import create from 'zustand';

const permissionsStore = create((set) => ({
  permissions: {},
  setPermissions: (permissions) => set({ permissions }),
  clearPermissions: () => set({ permissions: {} }),
}));

const modulesStore = create((set) => ({
  modules: {},
  setModules: (modules) => set({ modules }),
  clearModules: () => set({ modules: {} }),
}));


export { permissionsStore, modulesStore };