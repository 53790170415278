import React, { useState, useEffect} from 'react';
import '../../spinner.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getDetailedReportData } from '../../services/LearnerReportService';

export default function LearnerCertificate() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [detailedReport, setDetailedReport] = useState([]);
    const [certificates, setCertificates] = useState([]);

    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            getDetailedReport(); 
        }
    },[]);

    const getDetailedReport = async () => {
        const userDetails = getUserDetails();
        setLoading(true);
        const detailedReportData = await getDetailedReportData(userDetails.token);
        if(!detailedReportData.error){
            setDetailedReport(detailedReportData.data);
            let certs = {};
            let detailsReportForCerts = detailedReportData.data;
            for(let i=0; i<detailsReportForCerts.length; i++){
                if(detailsReportForCerts[i].module_name === 'Post Assessment'){
                    certs[detailsReportForCerts[i].course_id] = detailsReportForCerts[i];
                }
            }
            setCertificates(Object.values(certs));

        }else{
            toast.error(detailedReportData.data);
        } 
        setLoading(false);   
    }; 

    const downloadCertificate = async (cert) => {
        sessionStorage.setItem('cert', JSON.stringify(cert));
        navigate('/learner/certificatecompletion');
    };

    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
            ) : (
                <>
                {certificates.length > 0 ? (
                    <div className='py-20 lg:px-12 pl-6 pr-6'>
                        <h3 className='text-2xl text-secondary text-center inter-700 mb-3 md:mb-4 lg:mb-3 lg:hidden'>My Certificates</h3>
                        <div className=''>
                            <div className='mb-10 lg:mb-16'>
                                <h3 className='text-2xl md:text-3xl 2xl:text-4xl text-secondary inter-400 text-center'>Click to download the certificates.</h3>
                            </div>
                            <div className='flex flex-wrap justify-evenly gap-16'>
                            {certificates.map((value, index) => (
                                <a key={index} onClick={() => downloadCertificate(value)} className='cursor-pointer w-64 h-64 md:w-56 md:h-56 lg:w-64 lg:h-64 xl:w-80 xl:h-80 p-10 md:p-4 lg:p-6 xl:p-10 mb-6 md:mb-10 text-center flex flex-col justify-center items-center bg-gradient-to-bl from-[#58C181] to-[#1D548D] hover:bg-[length:200%] hover:scale-105 hover:-translate-y-4 hover:shadow-lg transition-all duration-300 ease-in-out rounded-3xl'>
                                    <div className='mb-5'>
                                        <img className='w-20 lg:w-28 xl:32 2xl:w-36' src='../images/badge-medal.svg' alt='badge medal'/>
                                    </div>
                                    <p className='text-2xl text-white inter-700'>{value.course_name}</p>
                                </a>
                            ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='py-20 lg:px-12 pl-6 pr-6'>
                    <h3 className='text-2xl text-black text-center inter-700 mb-3 md:mb-4 lg:mb-3'>Please complete the Post-Assessment of any technology to receive the certificate.</h3>
                    </div>
                )}
                </>
            )
          }
        </div>
    );
}