import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { addUser, updateUser, inviteUserWithEmail } from '../../services/UserService';
import { toast } from 'react-toastify';
import { getCountryList } from '../../services/CommonService';
import Select from 'react-select';
import { getAllCohortData } from '../../services/CohortService';

export default function InviteNewLearner() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [user, setUser] = useState({});
    const [cohorts, setCohorts] = useState([]);
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
          setUserDetails(userDetails);
          getCohorts(userDetails.token);
        }
        setUser({
            ...user,
            ["owner_email"]: (userDetails.user.owner_email === undefined || userDetails.user.owner_email === null) ? "": userDetails.user.owner_email
        });
    },[]);

    const handleCohortValueChange = async (value) => {
        setUser({
            ...user,
            ["cohort_id"]: value
        });

    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setUser({
          ...user,
          [name]: value,
        });
    };

    const validateUser = (user) => {
        return !(user.cohort_id === undefined || user.email === null || user.cohort_id === null || user.email === undefined);
    };

    const inviteUser = async () => {
        const userDetails = getUserDetails();
        const isValid = validateUser(user);

        if(isValid){
            setLoading(true);
            let resp = await inviteUserWithEmail(userDetails.token, user);
            if(resp.error){
                toast.error(resp.data);
            }else{
                toast.success("Email Sent!");
            }
            setLoading(false);
        }else{
            toast.warn("Either email or cohort is not valid!");
        }
        
    };

    const getCohorts = async (token) => {
        const cohortsData = await getAllCohortData(token);
        if(cohortsData.error){
            return [];
        }else{
            let ch = [];
            for(let i=0;i<cohortsData.data.length; i++){
                let chObj = {value: cohortsData.data[i].id, label: cohortsData.data[i].cohort_desc + " - "+ (cohortsData.data[i].org_name !== null && cohortsData.data[i].org_name !== undefined ? cohortsData.data[i].org_name : "")};
                ch.push(chObj);
            }
            setCohorts(ch);
        }
    };


    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='lg:py-10 lg:px-24 xl:px-32 py-10 px-10'>
                <h3 className='text-2xl text-secondary text-center inter-700 mb-6 md:mb-4 lg:mb-3 lg:hidden'>Invite New Learner</h3>
                <div className='back-btn flex justify-start mb-5 md:mb-8'>
                    <a onClick={() => navigate('/admin/managelearner')} className='cursor-ppointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                </div>
                <form className='w-full'>

                    <div className='flex justify-between flex-wrap'>
                        <div className='form-field w-full md:w-1/4 mb-5 md:mb-8'>
                            <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                options={cohorts}
                                value={cohorts.find(option => option.value === user.cohort_id)}
                                onChange={(values) => handleCohortValueChange(values.value)}
                            />
                        </div>
                    </div>
                    <div className='flex justify-between flex-wrap'>
                        <div className='form-field w-full md:w-3/5 mb-5 md:mb-8'>
                            <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Email ID to send invite</label>
                            <input type='email' name="email" value={user.email} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                        </div>
                    </div>
                    <div className='flex items-center mt-6 2xl:mt-10'>
                        <button onClick={inviteUser} type="button" className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>+ Invite New Learner</button>
                    </div>
                </form>
            </div>
            )}
        </div>
    );
}