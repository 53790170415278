import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { toast } from 'react-toastify';
import { formatDate, getTodayDateInUTC } from '../../services/CommonService';
import { updateCustomer } from '../../services/CustomerService';
import { getAllSubscriptions } from '../../services/SubscriptionService';
import Select from 'react-select';

export default function UpdateCustomer() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [customer, setCustomer] = useState({name: "", cust_type: "", max_learners: 0, first_name: "", middle_name: "", last_name: "", email: "", password: "", status: "ACTIVE", role: "ACCOUNT_OWNER", state: "", city: "", country: "USA", addr_line1: "", addr_line2: "", zip_code: "", phone_no: ""});
    const [subscriptions, setSubscriptions] = useState([]);
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            setUserDetails(userDetails);
            let updateCustomer = sessionStorage.getItem('updateCustomer') === null ? null : JSON.parse(sessionStorage.getItem('updateCustomer'));
            if(updateCustomer === null){
                toast.warn("Please choose a customer in Manage Customer Page!")
                navigate('/superadmin/managecustomer')

            }else{
                updateCustomer["customer_id"] = updateCustomer["id"];
                updateCustomer["subscription_end_date"] = updateCustomer["subscription_end_date"].split("T")[0];
                updateCustomer["subscription_start_date"] = updateCustomer["subscription_start_date"].split("T")[0];
                updateCustomer["original_subscription_code"] = updateCustomer["subscription_code"]
                setCustomer(updateCustomer);
                sessionStorage.removeItem('updateCustomer');
                getAllSubscriptionsData(userDetails.token);
            }

        }
    },[]);

    const getAllSubscriptionsData = async (token) => {
        setLoading(true);
        const subscriptionData = await getAllSubscriptions(token);
        if(subscriptionData.error){
            return [];
        }else{
            let subscriptionArray = [];
            for(let i=0;i<subscriptionData.data.length; i++){
                if(subscriptionData.data[i].subscription_status !== 'CANCELLED'){
                    subscriptionArray.push(subscriptionData.data[i]);
                }

            }
            setSubscriptions(subscriptionArray);
        }
        setLoading(false);
    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setCustomer({
          ...customer,
          [name]: value,
        });
    };

    const handleValueChangePlan = (plan) => {
        console.log(plan);
    }

    const updateCustomerData =  async () => {
        setLoading(true);
        let newCustomerData = Object.assign(customer);
        if(newCustomerData.original_subscription_code !== newCustomerData.subscription_code){
            newCustomerData["subscription_start_date"] = getTodayDateInUTC();
        }
        let resp = await updateCustomer(userDetails.token, newCustomerData);
        if(resp.error === false){
            toast.success("Customer Details updated!");
        }else{
            toast.error("Error while updating customer!");
        }
        setLoading(false);
    };
    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className=''>
                    <h3 className='text-2xl text-secondary text-left inter-700 md:mb-6 lg:hidden pt-6 lg:pt-0 px-6'>Modify Customer</h3>
                    <div className='lg:px-12 px-6'>
                        <div className='border-b border-[#7E7C7C] mt-6 md:pt-10 pb-6'>
                            <p className='text-2xl text-secondary inter-400 leading-none mb-2'>{customer.name}</p>
                            <p className='text-lg text-primary inter-400 leading-none mb-2'>Type - {customer.cust_type}</p>
                            <p className='text-lg text-[#9747FF] inter-400 leading-none mb-2'>{customer.subscription_code} Plan | {customer.max_learners} Users</p>
                            <p className='text-lg text-black inter-400 leading-none mb-2'>Valid from {formatDate(customer.subscription_start_date)} - {formatDate(customer.subscription_end_date)}</p>
                        </div>
                            <div className='border-b border-[#7E7C7C] pt-8 pb-8'>
                                <p className='text-lg text-black inter-700 leading-none mb-4'>Status</p>
                                <div className='flex items-center flex-wrap'>
                                    <div className='customer-radio-sngl w-full md:w-1/5 mb-5 md:mb-0'>
                                        <input type="radio" id="cancelled" name="customer_status" onChange={handleValueChange} value="CANCELLED" checked={customer.customer_status === 'CANCELLED'} />
                                        <label for="cancelled">Cancelled</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/5 mb-5 md:mb-0'>
                                        <input type="radio" id="suspended" name="customer_status" onChange={handleValueChange} value="SUSPENDED" checked={customer.customer_status === 'SUSPENDED'} />
                                        <label for="suspended">Suspended</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/5 md:mb-0'>
                                        <input type="radio" id="active" name="customer_status" onChange={handleValueChange} value="ACTIVE" checked={customer.customer_status === 'ACTIVE'} />
                                        <label for="active">Active</label>
                                    </div>
                                </div>
                            </div>
                            <div className='border-b border-[#7E7C7C] pt-8 pb-8'>
                                <p className='text-lg text-black inter-700 leading-none mb-4'>Subscription Details</p>
                                <div className='flex items-center flex-wrap'>
                                {subscriptions.length > 0 ? (
                                    <>
                                        {subscriptions.map((value1, index) => (
                                            <div key={index} className='customer-radio-sngl w-full md:w-1/5 mb-5 md:mb-0'>
                                                <input type="radio" id="plan" name="subscription_code" onChange={handleValueChange} value={value1.subscription_code} checked={customer.subscription_code === value1.subscription_code} />
                                                <label for={value1.subscription_desc}>{value1.subscription_desc}</label>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    null
                                )}

                                </div>
                            </div>
                            <div className='border-b border-[#7E7C7C] pt-8 pb-8'>
                                <div className='flex items-center flex-wrap'>
                                    <div className='w-full md:w-2/5'>
                                        <p className='text-lg text-black inter-700 leading-none mb-4 md:mb-0'>Update New User Licenses</p>
                                    </div>
                                    <div className='w-full md:w-1/5'>
                                    <input type='number' name="max_learners" value={customer.max_learners} onChange={handleValueChange} placeholder='0' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                    </div>
                                </div>
                            </div>
                            <div className='border-b border-[#7E7C7C] pt-8 pb-8'>
                                <div className='flex items-center flex-wrap'>
                                    <div className='w-full md:w-2/5'>
                                        <p className='text-lg text-black inter-700 leading-none mb-4 md:mb-0'>Update New Validity</p>
                                    </div>
                                    <div className='w-full md:w-1/5'>
                                    <input type='date' name="subscription_end_date" value={customer.subscription_end_date} onChange={handleValueChange} placeholder='United State' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-12 pb-6'>
                                <div className=''>
                                    <p className='text-lg text-black inter-700 leading-none mb-3 md:mb-3'>Extra Payable</p>
                                    <p className='text-2xl text-primary inter-700 leading-none mb-4 md:mb-0'>USD 4,500 + Tax</p>
                                </div>
                            </div>
                            <div className='flex items-center flex-wrap mt-0 2xl:mt-10 pb-8 md:pb-10'>
                                <button onClick={() => updateCustomerData()} className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-6 h-10 2xl:h-16 text-white inter-600 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow mb-5 md:mb-0 md:mr-8'>Update & Send Payment Link</button>
                                {/* <a href='pay' className='w-full md:w-auto bg-tertiary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-600 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Pay Online</a> */}
                            </div>
                    </div>
                </div>
            )}
        </div>
    );
}