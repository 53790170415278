import { API_URL } from "../utility/constants";

export async function getCustomerData(token) {
    try{
        const response = await fetch(API_URL+"users/customer/getCustomerData", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAllCustomers(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCustomer/getAllCustomers", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCustomerById(token, customer_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCustomer/getCustomerById?customer_id="+customer_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function updatePortalColors(token, data) {
    try{
        const response = await fetch(API_URL+"customer/customize/updatePortalColors", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function customerLogoUpload(token, data) {
    try{
        const response = await fetch(API_URL+'customer/customize/uploadCustomerLogo', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: data
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addCustomer(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminCustomer/addCustomer`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updateCustomer(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminCustomer/updateCustomer`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function deleteCustomerById(token, customer_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCustomer/deleteCustomerById?customer_id="+customer_id, {
            method: 'DELETE',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

