import { API_URL } from "../utility/constants";

export async function getCustomerDetail(token) {
    try{
        const response = await fetch(API_URL+"users/customer/getCustomerDetail", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCustomerCoursesData(token) {
    try{
        const response = await fetch(API_URL+"users/customer/getCustomerCourses", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};