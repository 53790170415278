import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getAllModules, getAssessments, deleteAssessment } from '../../services/CourseService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { pageSizes } from '../../utility/constants';
import { formatSixDigitNumber, capitalizeFirstLetter } from '../../services/CommonService';

export default function ManageAssessments() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [ modules, setModules ] = useState([]);

    const [courseModules, setCourseModules] = useState([]);
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(0);
    const [selectedModule, setSelectedModule] = useState({});
    const [assessments, setAssessments] = useState([]);
    const gridApi = useRef(null);
    const [colDefs, setColDefs] = useState([
        {headerName: "Seq No", field: "assessment_seq_no", resizable: true },
        {headerName: "Type", field: "type", resizable: true },
        {headerName: 'Status',
            valueGetter: (params) => {
                return params.data.assessment_status;
            },
            cellRenderer: params => {
                const onClick = () => {
                    sessionStorage.setItem("assessmentEdit", JSON.stringify(params.data));
                    routeAddNewAssessment();                
                };
                return (
                    <>
                        <div onClick={onClick} className='cursor-pointer flex items-center h-full'>
                            {params.data.assessment_status === 'PUBLISHED' ? (
                                <div className='active-status text-xs inter-500 leading-none bg-[#EBF9F1] text-[#1F9254] py-2 px-2 rounded-3xl'>Published</div>
                            ) : (
                                null
                            )}
                            {params.data.assessment_status === 'DRAFT' ? (
                                <div className='suspend-status text-xs inter-500 leading-none bg-[#FEF2E5] text-[#CD6200] py-2 px-2 rounded-3xl'>Draft</div>
                            ) : (
                                null
                            )}
                            {params.data.assessment_status === 'CANCELLED' ? (
                                <div className='cancel-status text-xs inter-500 leading-none bg-[#FBE7E8] text-[#A30D11] py-2 px-2 rounded-3xl'>Cancelled</div>
                            ) : (
                                null
                            )}
                        </div>
                    </>
                );
            },
            sortable: true,
            filter: true
        },
        {headerName: "Question", field: "primary_text", resizable: true, tooltipValueGetter: (p) =>
            p.value
        },
        // {headerName: "Options",
        //     valueGetter: (params) => {
        //         return params.data.option1;
        //     },
        //     cellRenderer: params => {
        //         return (
        //                 <ul>
        //                     {params.data.option1 !== null && params.data.option1 !== undefined ? (
        //                         <li>{params.data.option1}</li>
        //                     ) : (null)}
        //                     <li>{params.data.option2}</li>

        //                     {params.data.option2 !== null && params.data.option2 !== undefined ? (
        //                         <li>{params.data.option2}</li>
        //                     ) : (null)}
        //                     {params.data.option3 !== null && params.data.option3 !== undefined ? (
        //                         <li>{params.data.option3}</li>
        //                     ) : (null)}
        //                     {params.data.option4 !== null && params.data.option4 !== undefined ? (
        //                         <li>{params.data.option4}</li>
        //                     ) : (null)}
        //                     {params.data.option5 !== null && params.data.option5 !== undefined ? (
        //                         <li>{params.data.option5}</li>
        //                     ) : (null)}
        //                 </ul>
        //         )
        //     },
        //     sortable: true,
        //     filter: true
        // },
        {headerName: 'Actions',
            resizable: true,
            cellRenderer: params => {
                const onClickEdit = () => {
                    sessionStorage.setItem("assessmentEdit", JSON.stringify(params.data));
                    routeAddNewAssessment();
                };
                const onClickDelete = async () => {
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        cancelButtonText: 'No, cancel!',
                        reverseButtons: true
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            const userDetails = getUserDetails();
                            setLoading(true);
                            let resp = await deleteAssessment(userDetails.token, params.data.id);
                            if(resp.error){
                                toast.error(resp.data);
                            }else{
                                getAssessmentsData(params.data.module_id);
                                toast.success(resp.data);
                            }
                            setLoading(false);
                            
                        } 
                    });

                };

                return (
                    <>
                        <div className='relative course-navigate-btns flex items-center justify-between h-full'>
                            <a onClick={onClickEdit} className='cursor-pointer flex items-center justify-center transition duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M11.083 4H4.08301C3.55257 4 3.04387 4.21071 2.66879 4.58579C2.29372 4.96086 2.08301 5.46957 2.08301 6V20C2.08301 20.5304 2.29372 21.0391 2.66879 21.4142C3.04387 21.7893 3.55257 22 4.08301 22H18.083C18.6134 22 19.1221 21.7893 19.4972 21.4142C19.8723 21.0391 20.083 20.5304 20.083 20V13" stroke="#624DE3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.583 2.49998C18.9808 2.10216 19.5204 1.87866 20.083 1.87866C20.6456 1.87866 21.1852 2.10216 21.583 2.49998C21.9808 2.89781 22.2043 3.43737 22.2043 3.99998C22.2043 4.56259 21.9808 5.10216 21.583 5.49998L12.083 15L8.08301 16L9.08301 12L18.583 2.49998Z" stroke="#624DE3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                            <a onClick={onClickDelete} className='cursor-pointer flex items-center justify-center transition duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M3.08301 6H5.08301H21.083" stroke="#A30D11" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.08301 6V4C8.08301 3.46957 8.29372 2.96086 8.66879 2.58579C9.04387 2.21071 9.55257 2 10.083 2H14.083C14.6134 2 15.1221 2.21071 15.4972 2.58579C15.8723 2.96086 16.083 3.46957 16.083 4V6M19.083 6V20C19.083 20.5304 18.8723 21.0391 18.4972 21.4142C18.1221 21.7893 17.6134 22 17.083 22H7.08301C6.55257 22 6.04387 21.7893 5.66879 21.4142C5.29372 21.0391 5.08301 20.5304 5.08301 20V6H19.083Z" stroke="#A30D11" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10.083 11V17" stroke="#A30D11" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14.083 11V17" stroke="#A30D11" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                        </div>
                    </>
                );
            },
            
        }
    ]);
    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            flex: 1,
            minWidth: 150,
        };
    }, []);
    const onGridReady = (params) => {
        params.api.sizeColumnsToFit();
        gridApi.current = params.api;
    };
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            getAllModulesData(userDetails.token);
        }
    },[]);
    const getAllModulesData = async (token) => {
        setLoading(true);
        const modulesData = await getAllModules(token);
        if(modulesData.error === false){
            let allModules = modulesData.data;
            let courseObj = {};
            for(let i=0;i<allModules.length; i++){
                allModules[i]["module_id"] = allModules[i]["id"];
                if(courseObj[allModules[i].course_id] === undefined){
                    courseObj[allModules[i].course_id] = {label: allModules[i].course_name, value: allModules[i].course_id};

                }
            }
            setCourses(Object.values(courseObj));
            setModules(allModules);
            let moduleValue = sessionStorage.getItem('selectedModule') === null ? null : JSON.parse(sessionStorage.getItem('selectedModule'));
            if(moduleValue !== null){
                await handleCourseValueChange(moduleValue.course_id, allModules);
            }else{
                await handleCourseValueChange(allModules[0].course_id, allModules);
            }
            
        }
        if (gridApi.current) {
            gridApi.current.refreshCells();
        }
        setLoading(false);
    };

    const handleCourseValueChange = async (value, modules) => {
        setLoading(true);
        setSelectedCourse(value);
        let allModules = [];
        for(let i=0; i<modules.length; i++){
            if(parseInt(modules[i].course_id) === parseInt(value) && modules[i].module_type === 'ASSESSMENT'){
                allModules.push({...modules[i], label: modules[i].module_seq_no + " - " + modules[i].module_name, value: modules[i].id})
            }
        }
        setCourseModules(allModules);
        let moduleValue = sessionStorage.getItem('selectedModule') === null ? null : JSON.parse(sessionStorage.getItem('selectedModule'));
        if(moduleValue !== null && moduleValue.module_type === 'ASSESSMENT'){
            await handleModuleValueChange(moduleValue);
        }else{
            await handleModuleValueChange(allModules[0]);
        }
        setLoading(false);
    };

    const onCellClicked = (event) => {
        if (event.column.colId === 'primary_text') {
            Swal.fire({
                title: "Question",
                html: 
                `                        
                <p className='text-base inter-400 text-justify text-black text-bold mt-4'>
                ${event.value}
                </p>
                <ul className='question-list text-left'>
                    <li>
                        ${event.data.option1}
                    </li>
                    <li>
                        ${event.data.option2}
                    </li>
                    <li>
                        ${event.data.option3}
                    </li>
                    <li>
                        ${event.data.option4}
                    </li>
                    <li>
                        ${event.data.option5}
                    </li>
                <ul>
                ` ,
                showConfirmButton: true,
                confirmButtonText: "OK",
            });
        }
    };

    const handleModuleValueChange = async (moduleValue) => {
        setLoading(true);
        setSelectedModule(moduleValue);
        sessionStorage.setItem('selectedModule', JSON.stringify(moduleValue));
        const usrDtl = getUserDetails();
        let assessmentData = await getAssessments(usrDtl.token, moduleValue.value);
        if(assessmentData.error === false){
            setAssessments(assessmentData.data);
        }else{
            setAssessments([]);
            toast.error("Some error occurred while getting assessments!")
        }
        setLoading(false); 
    };

    const getAssessmentsData = async (value) => {
        setLoading(true);
        const usrDtl = getUserDetails();
        let assessmentData = await getAssessments(usrDtl.token, value);
        if(assessmentData.error === false){
            setAssessments(assessmentData.data);
        }else{
            setAssessments([]);
            toast.error("Some error occurred while getting assessments!")
        }
        setLoading(false); 
    };

    const routeAddNewAssessment = async () => {
        let moduleSelected = sessionStorage.getItem('selectedModule') === null ? {} : JSON.parse(sessionStorage.getItem('selectedModule'));
        if(moduleSelected.module_id !== undefined){
            sessionStorage.setItem('moduleValue', JSON.stringify(moduleSelected));
            navigate('/superadmin/addassessment');
        }else {
            toast.error("No Module is selected!");
        }

    };

    const previewAssessment = async () => {
        if(assessments.length > 0){
            sessionStorage.setItem('assessmentPreview', JSON.stringify(assessments));
            navigate('/superadmin/previewassessment');
        }else{
            toast.error("No Assessment Found for Preview");
        }
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='py-6 md:py-10'>
                    <h3 className='text-2xl text-secondary inter-700 px-6 lg:hidden mb-6'>Manage Assessment</h3>
                    <div className='flex justify-between flex-wrap lg:mx-12 lg:px-0 px-6 pb-5 mb-6 border-b-2 border-[#D0D0D0]'>
                        <button onClick={() => previewAssessment()} type="button" className='bg-tertiary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto mb-5 md:mb-0'>Preview Assessment</button>
                        <button onClick={() => routeAddNewAssessment()} type="button" className='bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>+ Add New Assessment</button>
                    </div>
                    <div className='flex justify-center flex-wrap mb-4 md:mb-6 px-6'>
                        <div className='w-full md:w-2/5 lg:w-1/3 md:mr-6 mb-4 md:mb-0'>
                            <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md relative z-10'
                                options={courses}
                                value={courses.find(option => option.value === selectedCourse)}
                                onChange={(values) => handleCourseValueChange(values.value, modules)}
                            />
                        </div>
                        <div className='w-full md:w-2/5 lg:w-1/3'>
                            <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md relative z-10'
                                options={courseModules}
                                value={courseModules.find(option => option.value === selectedModule.value)}
                                onChange={(values) => handleModuleValueChange(values)}
                            />
                        </div>
                    </div>
                    <div className='lg:px-20 px-6'>
                        {modules.length > 0 ? (
                            <div
                            className="ag-theme-quartz"  
                            style={{ width: '100%', height: 500 }} // the Data Grid will fill the size of the parent container
                            >
                                <AgGridReact
                                    rowData={assessments}
                                    columnDefs={colDefs}
                                    rowSelection="multiple"
                                    defaultColDef={defaultColDef}
                                    suppressRowClickSelection={true}
                                    pagination={true}
                                    paginationPageSize={5}
                                    paginationPageSizeSelector={pageSizes}
                                    onGridReady={onGridReady}
                                    tooltipShowDelay={500}
                                    rowClass='rowClass'
                                    onCellClicked={onCellClicked}
                                />
                            </div>
                        ) : (
                            null
                        )}

                    </div>
                </div>
            )}
        </div>
    );
}