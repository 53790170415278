import React, { useState, useEffect } from 'react'
import {useNavigate } from 'react-router-dom';
import { getUserDetails } from '../services/UserService';
import { getSuperAdminDashboardData } from '../services/AppAdminService';

export default function SADashboard() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: "Super Admin"}});
    const [dashboardData, setDashboardData] = useState({num_courses: 0, num_modules: 0, num_learners: 0, num_customers: 0, num_minutes: 0});
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            

            getData(userDetails.token);
        }
    },[]);
    const getData = async (token) => {
        setLoading(true);
        let resp = await getSuperAdminDashboardData(token);
        if(resp.error === false){
            setDashboardData(resp.data);
        }
        setLoading(false);
    }

    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
          ) : (
              <div className='dash-container pt-10'>
                  <h3 className='text-2xl text-black pacifico-regular text-center mb-4 md:mb-8 lg:hidden'>Welcome {userDetails.user.first_name}!</h3>
                  <div>
                      <div className='first-course-row flex flex-wrap md:flex-nowrap items-center justify-center lg:gap-x-20 md:gap-x-10 lg:pl-12 pl-6 pr-6 lg:pr-12 border-b-2 border-[#D0D0D0] pb-12 md:pb-8 lg-pb-12'>
                  
                          <div className='w-full lg:w-1/2 rounded-3xl shadow-md bg-gradient-to-r from-[#6E3174] to-[#9831A3] lg:px-14 lg-py-10 md:px-8 px-4 py-8 mb-10 md:mb-0'>
                              <div className='flex items-center'>
                                  <div className='w-1/2 pr-4'>
                                      <img className='' src='../images/data-graph.svg' alt='data'/>
                                  </div>
                                  <div className='w-1/2 pl-2 md:pl-0 lg:pl-6'>
                                      <div>
                                          <p className='text-sm lg:text-xl inter-700 text-[#F9EA98] mb-3'>{dashboardData.num_customers} Customers</p>
                                          <p className='text-sm lg:text-xl inter-700 text-[#F9EA98] mb-3'>{dashboardData.num_learners} Learners</p>
                                          <p className='text-sm lg:text-xl inter-700 text-[#F9EA98] mb-3'>{dashboardData.num_courses} Technologies</p>
                                          <p className='text-sm lg:text-xl inter-700 text-[#F9EA98] mb-3'>{dashboardData.num_modules} Modules</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='w-full lg:w-1/2 rounded-3xl shadow-md bg-gradient-to-r from-[#6E3174] to-[#9831A3] lg:px-14 md:pr-4 lg:pr-14 lg-py-10 px-4 py-8 mb-0 md:mb-0'>
                              <div className='flex items-center'>
                                  <div className='w-1/2 pr-4'>
                                      <img className='' src='../images/time-graph.svg' alt='time'/>
                                  </div>
                                  <div className='w-1/2 pl-2 md:pl-0 lg:pl-6'>
                                      <div className='mb-6'>
                                          {/* <p className='text-sm lg:text-4xl text-white inter-700'>{Math.ceil(dashboardData.num_minutes)}</p>
                                          <p className='text-sm lg:text-3xl text-white inter-300'>Minutes</p> */}
                                          <p className='text-lg lg:text-3xl text-white inter-700'>{Math.floor(dashboardData.num_minutes / 1440)} Day</p>
                                          <p className='text-sm md:text-base lg:text-xl text-white inter-500'>{Math.floor((dashboardData.num_minutes % 1440) / 60)} Hours : {Math.ceil(dashboardData.num_minutes % 60)} Mins</p>
                                      </div>
                                      <div>
                                          <p className='text-sm lg:text-2xl inter-500 text-white'>Spent by</p>
                                          <p className='text-sm lg:text-2xl inter-500 text-white'>Learners</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className='dash-univ-wrap mt-12 md:mt-8 lg-mt-12'>
                      <div className='univ-box-wrap flex flex-wrap gap-y-6 md:gap-x-[5%] lg:gap-x-[3.5%] mt-7 lg:px-12 px-6 pb-12'>
                          <div className='univ-box w-full md:w-[47.5%] lg:w-[31%]'>
                              <a onClick={() => navigate('/superadmin/manageappadmin')} className='cursor-pointer univ-box-sngl flex flex-col justify-center min-h-52 2xl:min-h-[230px] rounded-3xl py-6 lg:px-8 xl:px-10 px-6 block'>
                                  <div>
                                      <div className='univ-lernar mb-2'>
                                          <p className='text-xl lg:text-xl 2xl:text-2xl text-white inter-300 mb-1'>Manage</p>
                                          <p className='text-2xl lg:text-3xl 2xl:text-3xl text-white inter-600'>Users</p>
                                      </div>
                                      <div className='bg-[#58C181] lg:h-2.5 h-1.5 rounded-3xl'></div>
                                  </div>
                              </a>
                          </div>
                          <div className='univ-box w-full md:w-[47.5%] lg:w-[31%]'>
                              <a onClick={() => navigate('/superadmin/managecustomer')} className='cursor-pointer univ-box-sngl flex flex-col justify-center min-h-52 2xl:min-h-[230px] rounded-3xl py-6 lg:px-8 xl:px-10 px-6 block'>
                                  <div>
                                      <div className='univ-lernar mb-2'>
                                          <p className='text-xl lg:text-xl 2xl:text-2xl text-white inter-300 mb-1'>Manage</p>
                                          <p className='text-2xl lg:text-3xl 2xl:text-3xl text-white inter-600'>Customers</p>
                                      </div>
                                      <div className='bg-[#58C181] lg:h-2.5 h-1.5 rounded-3xl'></div>
                                  </div>
                              </a>
                          </div>
                          <div className='univ-box w-full md:w-[47.5%] lg:w-[31%]'>
                              <a onClick={() => navigate('/superadmin/managelearners')} className='cursor-pointer univ-box-sngl flex flex-col justify-center min-h-52 2xl:min-h-[230px] rounded-3xl py-6 lg:px-8 xl:px-10 px-6 block'>
                                  <div>
                                      <div className='univ-lernar mb-2'>
                                          <p className='text-xl lg:text-xl 2xl:text-2xl text-white inter-300 mb-1'>Manage</p>
                                          <p className='text-2xl lg:text-3xl 2xl:text-3xl text-white inter-600'>Learners</p>
                                      </div>
                                      <div className='bg-[#58C181] lg:h-2.5 h-1.5 rounded-3xl'></div>
                                  </div>
                              </a>
                          </div>
                          <div className='univ-box w-full md:w-[47.5%] lg:w-[31%]'>
                              <a onClick={() => navigate('/superadmin/managesubscription')} className='cursor-pointer univ-box-sngl flex flex-col justify-center min-h-52 2xl:min-h-[230px] rounded-3xl py-6 lg:px-8 xl:px-10 px-6 block'>
                                  <div>
                                      <div className='univ-lernar mb-2'>
                                          <p className='text-xl lg:text-xl 2xl:text-2xl text-white inter-300 mb-1'>Manage</p>
                                          <p className='text-2xl lg:text-3xl 2xl:text-3xl text-white inter-600'>Subscriptions</p>
                                      </div>
                                      <div className='bg-[#58C181] lg:h-2.5 h-1.5 rounded-3xl'></div>
                                  </div>
                              </a>
                          </div>
                          <div className='univ-box w-full md:w-[47.5%] lg:w-[31%]'>
                              <a onClick={() => navigate('/superadmin/manageorders')} className='cursor-pointer univ-box-sngl flex flex-col justify-center min-h-52 2xl:min-h-[230px] rounded-3xl py-6 lg:px-8 xl:px-10 px-6 block'>
                                  <div>
                                      <div className='univ-lernar mb-2'>
                                          <p className='text-xl lg:text-xl 2xl:text-2xl text-white inter-300 mb-1'>Manage</p>
                                          <p className='text-2xl lg:text-3xl 2xl:text-3xl text-white inter-600'>Orders</p>
                                      </div>
                                      <div className='bg-[#58C181] lg:h-2.5 h-1.5 rounded-3xl'></div>
                                  </div>
                              </a>
                          </div>
                          <div className='univ-box w-full md:w-[47.5%] lg:w-[31%]'>
                              <a onClick={() => navigate('/superadmin/managecourses')} className='cursor-pointer univ-box-sngl flex flex-col justify-center min-h-52 2xl:min-h-[230px] rounded-3xl py-6 lg:px-8 xl:px-10 px-6 block'>
                                  <div>
                                      <div className='univ-lernar mb-2'>
                                          <p className='text-xl lg:text-xl 2xl:text-2xl text-white inter-300 mb-1'>Manage</p>
                                          <p className='text-2xl lg:text-3xl 2xl:text-3xl text-white inter-600'>Technologies</p>
                                      </div>
                                      <div className='bg-[#58C181] lg:h-2.5 h-1.5 rounded-3xl'></div>
                                  </div>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          )}
        </div>
    );
}