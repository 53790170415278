import React, {createContext, useContext, useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch, useNavigate, Routes, Navigate } from 'react-router-dom';
import '../spinner.css';
import { getCustomerAdminPermission, getUserDetails, logout } from '../services/UserService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SAHeader from './layout/SAHeader';
import SASidebar from './layout/SASidebar';
import SADashboard from './SADashboard';
import SAUserProfile from './SAUserProfile';
import SANoAccess from './SANoAccess';
import SAPasswordReset from './SAPasswordReset';
import SAProfile from './SAProfile';
import ManageCustomer from './customer/ManageCustomer';
import CreateCustomer from './customer/CreateCustomer';
import UpdateCustomer from './customer/UpdateCustomer';
import ManageAppAdmin from './appadmin/ManageAppAdmin';
import CreateAppAdmin from './appadmin/CreateAppAdmin';
import AppAdminPermissions from './appadmin/AppAdminPermissions';
import UserProfile from './UserProfile';
import SuperAdminManageLearners from './learners/SuperAdminManageLearners';
import SuperAdminInviteLearner from './learners/SuperAdminInviteLearner';
import SuperAdminCreateLearner from './learners/SuperAdminCreateLearner';
import SuperAdminManageCohort from './cohorts/SuperAdminManageCohort';
import SuperAdminCreateCohort from './cohorts/SuperAdminCreateCohort';
import SuperAdminCohortCourse from './cohorts/SuperAdminCohortCourse';
import ManageCourses from './courses/ManageCourses';
import AddCourse from './courses/AddCourse';
import AddModuleInCourse from './courses/AddModuleInCourse';
import EditModuleInCourse from './courses/EditModuleInCourse';
import ManageSubscription from './subscriptions/ManageSubscription';
import ManageOrders from './subscriptions/ManageOrders';
import ManageModules from './courses/ManageModules';
import AddModule from './courses/AddModule';
import AddPedagogy from './courses/AddPedagogy';
import AddPedagogyContent from './courses/AddPedagogyContent';
import ManagePedagogies from './courses/ManagePedagogies';
import AddSubscription from './subscriptions/AddSubscription';
import SuperAdminAnalytics from './reports/SuperAdminAnalytics';
import SuperAdminReport from './reports/SuperAdminReport';
import SuperAdminCohortReport from './reports/SuperAdminCohortReport';
import SuperAdminLearnerReport from './reports/SuperAdminLearnerReport';
import SuperAdminLearningReport from './reports/SuperAdminLearningReport';
import SuperAdminUserReport from './reports/SuperAdminUserReport';
import ManageAssessments from './courses/ManageAssessments';
import AddAssessment from './courses/AddAssessment';
import { permissionsStore } from '../services/Stores';
import PreviewAssessment from './courses/PreviewAssessment';
import PreviewPedagogy from './courses/PreviewPedagogy';
import SuperAdminCustomerReport from './reports/SuperAdminCustomerReport';
import SuperAdminCustomerAdminReport from './reports/SuperAdminCustomerAdminReport';
const useAuthorization = (permission, permissions) => {
//   const permissions = {"P11" : true, "P12": true, "P13": true, "P14": true, "P15": true}
  const navigate = useNavigate();

  if (permission !== "P10" && !permissions[permission]) {
    navigate('/superadmin/noaccess', { replace: true });
    return false;
  }

  return true;
};

const ProtectedRoute = ({ element: Element, permission, permissions }) => {
  const navigate = useNavigate();
  const isAuthorized = useAuthorization(permission, permissions);
  if(isAuthorized === false){
    return null;
  }else{
    return <Element /> 
  }

};

export default function SARoutes() {
  const navigate = useNavigate();
  const { permissions, setPermissions, clearPermissions } = permissionsStore();
  useEffect(() => {
      const userDetails = getUserDetails();
      if(userDetails.token === undefined){
        navigate('/login');
      } else if(userDetails.user.role === "LEARNER"){
        navigate('/learner/dashboard');
      } else if(userDetails.user.role === "ACCOUNT_OWNER"){
        navigate('/admin/dashboard');
      } else{
        getUserPermissions(userDetails.token, userDetails.user.id);
      }
  },[]);
  const getUserPermissions = async (token, id) => {
        setPermissions({"P11": true, "P12": true, "P13": true, "P14": true, "P15": true, "P16": true, "P17": true, "P18": true, "P19": true, "P20": true});

        // let resp = await getCustomerAdminPermission(token, id);
        // if(resp.error || resp.data.length === 0){
        //     setPermissions({"P1": true, "P2": true, "P3": true, "P4": true, "P5": true});
        //     //sessionStorage.setItem("permissions", JSON.stringify({"P1": true, "P2": true, "P3": true, "P4": true, "P5": true}));
        // }else{
        //     let permsObj = {};
        //     for( let i=0;i<resp.data.length; i++){

        //     permsObj[resp.data[i].permission_code] = true;
        //     }
        //     //sessionStorage.setItem("permissions", JSON.stringify(permsObj));
        //     setPermissions(permsObj);

        // }
  };
  return (
    <div className='flex'>
      <div className='sidebar-wrap fixed top-0 left-0 h-full hidden lg:block z-50'>
        <SASidebar />
      </div>
      <div className='dash-rigth'>
        <div className='header-wrap'>
          <SAHeader />
        </div>

         <Routes>
          <Route path="*" element={<SADashboard/>} permission="P10" permissions={permissions} />
          <Route path="/dashboard" element={<SADashboard/>} permission="P10" permissions={permissions} />
          
          <Route path="/manageappadmin" element={<ProtectedRoute element={ManageAppAdmin} permission="P11" permissions={permissions} />} />
          <Route path="/createappadmin" element={<ProtectedRoute element={CreateAppAdmin} permission="P11" permissions={permissions} />} />
          <Route path="/appadminpermissions" element={<ProtectedRoute element={AppAdminPermissions} permission="P11" permissions={permissions} />} />


          <Route path="/managecustomer" element={<ProtectedRoute element={ManageCustomer} permission="P12" permissions={permissions} />} />
          <Route path="/createcustomer" element={<ProtectedRoute element={CreateCustomer} permission="P12" permissions={permissions} />} />
          <Route path="/updatecustomer" element={<ProtectedRoute element={UpdateCustomer} permission="P12" permissions={permissions} />} />


          <Route path="/managecohort" element={<ProtectedRoute element={SuperAdminManageCohort} permission="P13" permissions={permissions} />} />
          <Route path="/createcohort" element={<ProtectedRoute element={SuperAdminCreateCohort} permission="P13" permissions={permissions} />} />
          <Route path="/cohortcourses" element={<ProtectedRoute element={SuperAdminCohortCourse} permission="P13" permissions={permissions} />} />

          <Route path="/managelearners" element={<ProtectedRoute element={SuperAdminManageLearners} permission="P14" permissions={permissions} />} />
          <Route path="/invitenewlearner" element={<ProtectedRoute element={SuperAdminInviteLearner} permission="P14" permissions={permissions} />} />
          <Route path="/createlearner" element={<ProtectedRoute element={SuperAdminCreateLearner} permission="P14" permissions={permissions} />} />

          <Route path="/managecourses" element={<ProtectedRoute element={ManageCourses} permission="P15" permissions={permissions} />} />
          <Route path="/addcourse" element={<ProtectedRoute element={AddCourse} permission="P15" permissions={permissions} />} />
          <Route path="/addmoduleincourse" element={<ProtectedRoute element={AddModuleInCourse} permission="P15" permissions={permissions} />} />
          <Route path="/editmoduleincourse" element={<ProtectedRoute element={EditModuleInCourse} permission="P15" permissions={permissions} />} />

          <Route path="/managemodules" element={<ProtectedRoute element={ManageModules} permission="P16" permissions={permissions} />} />
          <Route path="/addmodule" element={<ProtectedRoute element={AddModule} permission="P16" permissions={permissions} />} />

          <Route path="/addpedagogy" element={<ProtectedRoute element={AddPedagogy} permission="P17" permissions={permissions} />} />
          <Route path="/managepedagogy" element={<ProtectedRoute element={ManagePedagogies} permission="P17" permissions={permissions} />} />
          <Route path="/manageassessment" element={<ProtectedRoute element={ManageAssessments} permission="P17" permissions={permissions} />} />
          <Route path="/addassessment" element={<ProtectedRoute element={AddAssessment} permission="P17" permissions={permissions} />} />
          <Route path="/previewassessment" element={<ProtectedRoute element={PreviewAssessment} permission="P17" permissions={permissions} />} />
          <Route path="/previewpedagogy" element={<ProtectedRoute element={PreviewPedagogy} permission="P17" permissions={permissions} />} />

          {/* <Route path="/addpedagogycontent" element={<ProtectedRoute element={AddPedagogyContent} permission="P17" permissions={permissions} />} /> */}


          <Route path="/managesubscription" element={<ProtectedRoute element={ManageSubscription} permission="P18" permissions={permissions} />} />
          <Route path="/addsubscription" element={<ProtectedRoute element={AddSubscription} permission="P18" permissions={permissions} />} />

          <Route path="/manageorders" element={<ProtectedRoute element={ManageOrders} permission="P19" permissions={permissions} />} />
          
          <Route path="/analytics" element={<ProtectedRoute element={SuperAdminAnalytics} permission="P20" permissions={permissions} />} />
          <Route path="/superadminreport" element={<ProtectedRoute element={SuperAdminReport} permission="P20" permissions={permissions} />} />
          <Route path="/superadmincohortreport" element={<ProtectedRoute element={SuperAdminCohortReport} permission="P20" permissions={permissions} />} />
          <Route path="/superadminlearnerreport" element={<ProtectedRoute element={SuperAdminLearnerReport} permission="P20" permissions={permissions} />} />
          <Route path="/superadminlearningreport" element={<ProtectedRoute element={SuperAdminLearningReport} permission="P20" permissions={permissions} />} />
          <Route path="/superadminuserreport" element={<ProtectedRoute element={SuperAdminUserReport} permission="P20" permissions={permissions} />} />
          <Route path="/superadmincustomerreport" element={<ProtectedRoute element={SuperAdminCustomerReport} permission="P20" permissions={permissions} />} />
          <Route path="/superadmincustomeradminreport" element={<ProtectedRoute element={SuperAdminCustomerAdminReport} permission="P20" permissions={permissions} />} />



          <Route path="/noaccess" element={<SANoAccess/>} permission="P10" permissions={permissions} />
          <Route path="/passwordreset" element={<SAPasswordReset/>} permission="P10" permissions={permissions} />
          <Route path="/profile" element={<SAProfile/>} permission="P10" permissions={permissions} />
          <Route path="/userprofile" element={<ProtectedRoute element={UserProfile} permission="P10" permissions={permissions} />} />

         </Routes>

      </div>
    </div>
  );
};
