import React, { useState, useEffect } from 'react'
import { getUserDetails, logout, clearStore } from '../../services/UserService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { awsCdnPrefix, customerPicturePrefix } from '../../utility/constants';
import { permissionsStore } from '../../services/Stores';

export default function SAHeader() {
  const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
  const [mobileHeaderClicked, setMobileHeaderClicked] = useState(false);
  const [customerLogoLink, setCustomerLogoLink] = useState(awsCdnPrefix+customerPicturePrefix+"0.png");
  const { permissions, setPermissions, clearPermissions } = permissionsStore();
  const navigate = useNavigate();
  useEffect(() => {
    const userDetails = getUserDetails();
    if(userDetails.token !== undefined){
      setUserDetails(userDetails);
    }
  },[]);
  const changeMobileHeader = () => {
    mobileHeaderClicked ? setMobileHeaderClicked(false) : setMobileHeaderClicked(true);
  };

  const logoutUser = async () => {
    const userDetails = getUserDetails();
    if(userDetails.token !== undefined){
        let resp = await logout(userDetails.token);
        if(resp.error===false){
          toast.success(resp.data);
          navigate('/login');
        }else{
          toast.error(resp.data);
        }
    }else{
        clearStore();
        navigate('/login');
        toast.success("Successfully Logged Out!");
    }
    
  };

  const routeUser = (route) => {
    setMobileHeaderClicked(false);
    navigate(route);
  };

  return (
    <div>
        {/* Desktop header start here*/}
        <div className='site-header lg:flex hidden justify-between items-center py-3 px-12 border-b border-[#F8881B]'>
            <div className='wlcm-txt'>
                <h2 className='pacifico-regular text-black text-3xl'>Welcome {userDetails.user.first_name}!</h2>
                {/* show this when comes to course detail page  start*/}
                <h2 className='inter-700 text-secondary text-3xl hidden'>Classic Quantum</h2>
                {/* show this when comes to course detail page  start end*/}
            </div>
            <div className='header-logos flex'>
              <div className='header-logo-sngl mr-10'>
                <img onClick={() => window.open("https://quantumliteracy.org", '_blank')} className='cursor-pointer h-11' src='../images/quantum-black.webp' alt='quantum logo'/>
              </div>
              <div className='header-logo-sngl'>
                <img className='h-11' src={customerLogoLink} alt='sqc logo'/>
              </div>
            </div>
        </div>
        {/* Desktop header end here*/}
        {/* Mobile Header start Here */}
        <div className='mob-site-header shadow-md overflow-hidden py-4 px-6 flex lg:hidden justify-between bg-center bg-no-repeat bg-cover bg-primary'>
          <div className='mob-site-logo'>
            <img onClick={() => window.open("https://quantumliteracy.org", '_blank')} className='cursor-pointer h-6' src='../images/qlite-logo-w.webp' alt='qlite logo'/>
          </div>
          <div className='mob-header-icons flex'>
            
            <div className='dash-ham flex justify-center ml-10 transition-all duration-300 ease-in-out'>
              {/* Add "active" class in "mob-ham-icon" on click of hamburger */}
              <button onClick={() => changeMobileHeader()} type='button' className={mobileHeaderClicked? 'mob-ham-icon active' : 'mob-ham-icon'}>
                <span className='w-8 2xl:w-10 h-0.5 bg-white block mb-1.5 2xl:mb-2 transition-transform duration-300 ease-in-out'></span>
                <span className='w-6 ml-auto 2xl:w-7 h-0.5 bg-white block mb-1.5 2xl:mb-2 transition-transform duration-300 ease-in-out'></span>
                <span className='w-8 2xl:w-4 h-0.5 bg-white block transition-transform duration-300 ease-in-out'></span>
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Header end Here */}
        {/* Mobile Menu start Here */}
        {/* Add "active" class in "mob-menu-wrap" on click of hamburger */}
        <div className={mobileHeaderClicked? 'mob-menu-wrap active shadow-md bg-[#6E3174] fixed left-0 w-full transition-transform duration-300 ease-in-out z-50' : 'mob-menu-wrap shadow-md bg-[#6E3174] fixed left-0 w-full transition-transform duration-300 ease-in-out z-50'}>
          <div className='p-10'>
            <ul className='mb-10'>
              <li className='text-right mb-2'>
                <a onClick={() => routeUser('/superadmin/dashboard')} className='text-white inter-400 text-xl'>Dashboard</a>
              </li>
              {permissions["P11"] !== undefined && permissions["P11"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/manageappadmin')} className='text-white inter-400 text-xl'>App Admin</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P12"] !== undefined && permissions["P12"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/managecustomer')} className='text-white inter-400 text-xl'>Customers</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P13"] !== undefined && permissions["P13"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/managecohort')} className='text-white inter-400 text-xl'>Cohorts</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P14"] !== undefined && permissions["P14"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/managelearners')} className='text-white inter-400 text-xl'>Learners</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P15"] !== undefined && permissions["P15"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/managecourses')} className='text-white inter-400 text-xl'>Technology</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P16"] !== undefined && permissions["P16"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/managemodules')} className='text-white inter-400 text-xl'>Modules</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P17"] !== undefined && permissions["P17"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/managepedagogy')} className='text-white inter-400 text-xl'>Pedagogy</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P17"] !== undefined && permissions["P17"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/manageassessment')} className='text-white inter-400 text-xl'>Assessment</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P18"] !== undefined && permissions["P18"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/managesubscription')} className='text-white inter-400 text-xl'>Subscriptions</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P19"] !== undefined && permissions["P19"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/manageorders')} className='text-white inter-400 text-xl'>Orders</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P20"] !== undefined && permissions["P20"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/analytics')} className='text-white inter-400 text-xl'>Analytics</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P20"] !== undefined && permissions["P20"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/superadmin/superadminreport')} className='text-white inter-400 text-xl'>Reports</a>
                  </li>
              ): (
                  null
              )}
 
            </ul>
            <ul>
              <li className='text-right mb-2'>
                <a onClick={() => routeUser('/superadmin/profile')} className='text-white inter-400 text-xl'>My Profile</a>
              </li>
              <li className='text-right mb-2'>
                <a onClick={() => routeUser('/superadmin/passwordreset')} className='text-white inter-400 text-xl'>Change Password</a>
              </li>
              <li className='text-right mb-2'>
                <a onClick={logoutUser} className='text-white inter-400 text-xl'>Log Out</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Mobile Menu end Here */}
    </div>
  );
}
