import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { toast } from 'react-toastify';
import { getModuleByCourseId } from '../../services/CourseService';
import Select from 'react-select';

export default function EditModuleInCourse() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [edit, setEdit] = useState(false);
    const [course, setCourse] = useState({});
    const [modules, setModules] = useState([]);
    useEffect(() => {
        const userDetail = getUserDetails();
        if(userDetail.token === undefined){
          navigate('/login');
        }else{
            setUserDetails(userDetail);
            let editModuleInCourse = sessionStorage.getItem('editModuleInCourse') === null ? null : JSON.parse(sessionStorage.getItem('editModuleInCourse'));
            if(editModuleInCourse !== null){
                setCourse(editModuleInCourse);
                setEdit(true);
                sessionStorage.removeItem('editModuleInCourse');
                getModuleByCourseIdData(userDetail.token, editModuleInCourse.course_id)
            }else{
                toast.warn("Please select a course!");
                navigate('/superadmin/managecourses')   
            }
        }
    },[]);

    const getModuleByCourseIdData = async (token, course_id) => {
        setLoading(true);
        let resp = await getModuleByCourseId(token, course_id);
        if(resp.error === false){
            setModules(resp.data);
        }
        setLoading(false);
    };

    const onClickEdit = async (module) => {
        sessionStorage.setItem('moduleToEdit', JSON.stringify(module));
        sessionStorage.setItem('addModuleInCourse', JSON.stringify(course));
        navigate('/superadmin/addmoduleincourse');
    };


    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
            <div className='py-10 lg:px-12 px-6'>
                <h3 className='text-2xl text-secondary inter-700 mb-6 lg:hidden'>Add New Module to a Technology</h3>
                <div>
                    <h4 className='text-xl md:text-2xl text-[#121212] text-left inter-700 mb-3'>{course.course_name}</h4>
                    {modules.length > 0 ? (
                        <>
                            <p className='text-base md:text-xl text-[#121212] text-left inter-400 mb-6'>Existing Modules</p>
                            <div className='flex flex-wrap md:w-4/5 md:mb-8 mb-6'>
                                {modules.map((module, index) => (
                                <div key={index} className='w-full md:w-1/2 flex items-center flex-wrap pr-0 md:pr-8 mb-4'>
                                    <div className='w-full md:w-3/4 mb-4 md:mb-0'>
                                        <p className='text-lg text-[#9E9E9E] inter-400'>{module.module_seq_no} . {module.module_code} - {module.module_name}</p>
                                    </div>
                                    <div className='w-full md:w-1/4'>
                                        <a onClick={() => onClickEdit(module)} className='cursor-pointer w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-6 h-8 2xl:h-16 text-white inter-500 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow mb-5 md:mb-0'>+ Edit</a>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <p className='text-base md:text-xl text-[#121212] text-left inter-400 mb-6'>No Modules Found in Selected Technology</p>
                    )}

                    <div className='flex items-center justify-between flex-wrap'>
                        <a onClick={() => navigate('/superadmin/managecourses')} className='cursor-pointer w-full md:w-auto bg-tertiary hover:bg-black flex items-center justify-center px-8 h-10 2xl:h-16 text-white inter-500 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow mb-5 md:mb-0'>Cancel</a>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}