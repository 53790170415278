import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { toast } from 'react-toastify';
import { getModuleByCourseId, createModule, updateModule } from '../../services/CourseService';
import Select from 'react-select';

export default function AddModuleInCourse() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [edit, setEdit] = useState(false);
    const [course, setCourse] = useState({});
    const [modules, setModules] = useState([]);
    const [module, setModule] = useState({});
    const [moduleSeqNo, setModuleSeqNo] = useState(1);
    useEffect(() => {
        const userDetail = getUserDetails();
        if(userDetail.token === undefined){
          navigate('/login');
        }else{
            setUserDetails(userDetail);
            let moduleToEdit = sessionStorage.getItem('moduleToEdit') === null ? null : JSON.parse(sessionStorage.getItem('moduleToEdit'));
            if(moduleToEdit !== null){
                setModule(moduleToEdit);
                sessionStorage.removeItem('moduleToEdit');
                setModuleSeqNo(moduleToEdit.module_seq_no);
                setEdit(true);
            }
            let addModuleInCourse = sessionStorage.getItem('addModuleInCourse') === null ? null : JSON.parse(sessionStorage.getItem('addModuleInCourse'));
            if(addModuleInCourse !== null){
                setCourse(addModuleInCourse);
                sessionStorage.removeItem('addModuleInCourse');
                getModuleByCourseIdData(userDetail.token, addModuleInCourse.course_id, moduleToEdit)
            }else{
                toast.warn("Please select a course!");
                navigate('/superadmin/managecourses');   
            }
            
        }
    },[]);

    const getModuleByCourseIdData = async (token, course_id, moduleToEdit) => {
        setLoading(true);
        let resp = await getModuleByCourseId(token, course_id);
        if(resp.error === false){
            setModules(resp.data);
            let seq_no = 0;
            for(let i=0;i<resp.data.length; i++){
                seq_no = Math.max(seq_no, resp.data[i].module_seq_no);
            }
            if(moduleToEdit === null){
                setModuleSeqNo(seq_no + 1);
            }
        }
        setLoading(false);
    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setModule({
          ...module,
          [name]: value,
        });
    };

    const handleCodeChange = (code) => {
        setModule({
          ...module,
          ["module_code"]: code,
        });
    };

    const validateModule = (module) => {
        if(module.module_code === undefined || module.module_code === null || module.module_code === ''
            || module.module_name === undefined || module.module_name === null || module.module_name === ''
        ){
            return false;
        }
        return true;
    };

    const addModule = async () => {
        let objectToPost = Object.assign(module);
        objectToPost["module_seq_no"] = moduleSeqNo;
        objectToPost["module_desc"] = objectToPost["module_name"];
        objectToPost["module_type"] = objectToPost["module_code"] === 'PR' || objectToPost["module_code"] === 'PO' ? "ASSESSMENT" : "THEORY";
        objectToPost["course_id"] = course.course_id;
        if(validateModule(objectToPost)){
            setLoading(true);
            let resp = await createModule(userDetails.token, objectToPost);
            if(resp.error === false){
                toast.success("Module added successfully!");
            }else{
                toast.error("Some error occurred while adding module!");
            }
            setLoading(false);
        }else{
            toast.warn("All fields are mandatory! Please fill all fields.")
        }

    };

    const updateModuleData = async () => {
        let objectToPost = Object.assign(module);
        objectToPost["module_id"] = objectToPost["id"];
        if(validateModule(objectToPost)){
            setLoading(true);
            let resp = await updateModule(userDetails.token, objectToPost);
            if(resp.error === false){
                toast.success("Module updated successfully!");
            }else{
                toast.error("Some error occurred while updating module!");
            }
            setLoading(false);
        }else{
            toast.warn("All fields are mandatory! Please fill all fields.")
        }
        
    };


    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='lg:py-10 py-6'>
                    <h3 className='text-2xl text-secondary inter-700 mb-6 lg:hidden pt-6 lg:pt-0 px-6'>{edit ? "Update Module" : "Add New Module to a Technology"}</h3>
                    <form>
                        <div className='pt-10 lg:px-12 px-6 pb-4 bg-[#FDF8F8] border-b border-[#D9D9D9]'>
                            <p className='text-xl text-[#9E9E9E] inter-700 mb-3'>{course.course_name}</p>
                            <p className='text-lg text-[#9E9E9E] inter-400 mb-3 md:mb-3'>Existing Modules</p>
                            <div className='flex flex-wrap'>
                            {modules.map((moduleObj, index) => (
                                <div key={index} className='w-full md:w-1/2'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-2'><span>{moduleObj.module_seq_no} .</span> {moduleObj.module_code} - {moduleObj.module_name}</p>
                                </div>
                            ))}
                            </div>
                        </div>
                        <div className='py-10 lg:px-12 px-6 bg-[#FBF9F9] border-b border-[#D9D9D9]'>
                            <div className='flex flex-wrap mb-6'>
                                <div className='w-10/12 md:w-1/2'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-2'>{edit ? "Module Serial Number:" : "New Module Serial Number:"}</p>
                                </div>
                                <div className='w-2/12 md:w-1/2'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-2 text-right md:text-left'>{moduleSeqNo}</p>
                                </div>
                            </div>
                            <div className='form-field w-full md:w-[30%]'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-[#6F7482]'>Give a name for this Module</label>
                                <input type='text' name="module_name" value={module.module_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                        </div>
                        <div className='lg:px-12 px-6 pb-10'>
                            <div className='pt-10 pb-2 border-b border-[#D9D9D9]'>
                                <p className='text-xl 2xl:text-2xl text-[#BF2D88] inter-400 mb-6'>Select the type of Module for this Module</p>
                                <div className='flex items-center flex-wrap w-2/3'>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="preassessment" name="radio-group" value='PR' checked={module["module_code"] === "PR"} onChange={() => handleCodeChange("PR")} />
                                        <label for="preassessment" className=''>Pre-assessment</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="theory" name="radio-group" value='TH' checked={module["module_code"] === "TH"} onChange={() => handleCodeChange("TH")} />
                                        <label for="theory">Theory</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="application" name="radio-group" value='AP' checked={module["module_code"] === "AP"} onChange={() => handleCodeChange("AP")} />
                                        <label for="application">Application</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="component" name="radio-group" value='CO' checked={module["module_code"] === "CO"} onChange={() => handleCodeChange("CO")} />
                                        <label for="component">Components</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="taxonomy" name="radio-group" value='TA' checked={module["module_code"] === "TA"} onChange={() => handleCodeChange("TA")} />
                                        <label for="taxonomy">Taxonomy</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="schematics" name="radio-group" value='SCH' checked={module["module_code"] === "SCH"} onChange={() => handleCodeChange("SCH")} />
                                        <label for="schematics">Schematics</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="chain" name="radio-group" value='SC' checked={module["module_code"] === "SC"} onChange={() => handleCodeChange("SC")} />
                                        <label for="chain">Supply Chain</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="visual" name="radio-group" value='V' checked={module["module_code"] === "V"} onChange={() => handleCodeChange("V")} />
                                        <label for="visual">Visualization</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="problem" name="radio-group" value='PS' checked={module["module_code"] === "PS"} onChange={() => handleCodeChange("PS")} />
                                        <label for="problem">Problem-solving</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="postassessment" name="radio-group" value='PO' checked={module["module_code"] === "PO"} onChange={() => handleCodeChange("PO")} />
                                        <label for="postassessment">Post-assessment</label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='pt-8'>
                                <p className='text-xl 2xl:text-2xl text-[#BF2D88] inter-400 mb-6'>Available in </p>
                                <div className='flex items-center flex-wrap w-2/3'>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="bronze" name="radio-group" />
                                        <label for="bronze" className=''>Bronze</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="silver" name="radio-group" />
                                        <label for="silver">Silver</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="gold" name="radio-group" />
                                        <label for="gold">Gold</label>
                                    </div>
                                </div>
                            </div> */}
                            <div className='flex items-center justify-between flex-wrap pt-8'>
                                <a onClick={() => navigate('/superadmin/managecourses')} className='cursor-pointer w-full md:w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-10 2xl:h-16 text-white inter-500 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow mb-5 md:mb-0'>Back</a>
                                {edit ? (
                                    <button type="button" onClick={updateModuleData} className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Update Module</button>
                                ) : (
                                    <button type="button" onClick={addModule} className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>+ Add Module</button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}