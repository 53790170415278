import { API_URL } from "../utility/constants";

export async function getAllCohorts(customerId, token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCohorts/getAllCohorts?customer_id="+customerId, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAllCohortsForAllCustomers(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCohorts/getAllCohorts", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function deleteCohort(token, cohort_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCohorts/deleteCohort?cohort_id="+cohort_id, {
            method: 'DELETE',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCohortById(token, cohort_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCohorts/getCohortById?cohort_id="+cohort_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addCohort(data, token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCohorts/addCohort", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function updateCohort(data, token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCohorts/updateCohort", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCustomerCoursesData(token, customer_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCohorts/getCustomerCourses?customer_id="+customer_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCohortCoursesData(token, cohort_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCohorts/getCohortCoursesById?cohort_id="+cohort_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addCohortCourseData(token, data) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCohorts/addCohortCourses", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function updateCohortCourseData(token, data) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCohorts/updateCohortCourses", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};
