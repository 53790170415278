import { API_URL } from "../utility/constants";

export async function getAssignedCoursesData(data) {
    try{
        const response = await fetch(API_URL+"courses/learning/getUserCourses", {
            headers: {
              'Authorization': 'Bearer ' + data.token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getUserCoursesAndModulesAndLearningsData(token, data) {
    try{
        const response = await fetch(API_URL+"courses/learning/getUserCoursesAndModulesAndLearningsV2", {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({course_ids: data})
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAssessmentData(token, data) {
    try{
        const response = await fetch(API_URL+"courses/assessments/getAssessment", {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({module_id: data})
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getPedagogyData(token, data) {
    try{
        const response = await fetch(API_URL+"courses/pedagogy/getAllPedagogyByModuleId", {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({module_id: data})
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function postAssessmentData(token, data) {
    try{
        const response = await fetch(API_URL+`courses/userAssessments/postAssessment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function postScore(token, data) {
    try{
        const response = await fetch(API_URL+`courses/userAssessments/postScore`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function getUserAssessmentsData(token, data) {
    try{
        const response = await fetch(API_URL+"courses/userAssessments/getUserAssessment", {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({module_id: data})
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function postLearning(token, data) {
    try{
        const response = await fetch(API_URL+`courses/learning/addPedagogyLearning`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function getUserPedagogyLearningData(data, token) {
    try{
        const response = await fetch(API_URL+`courses/learnerReport/getUserPedagogyLearning`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};
