import React, { useState, useEffect} from 'react';
import '../../spinner.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { postScore } from '../../services/LearningService';

export default function LearnerCongrats() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [score, setScore] = useState([]);
    const [module, setModule] = useState({});
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            calculateScore();
        }
        
    },[]);
    const calculateScore = async () => {
        const userDetails = getUserDetails();
        let module = sessionStorage.getItem('currentModule') !== undefined ? JSON.parse(sessionStorage.getItem('currentModule')) : {};
        setModule(module);
        let score = sessionStorage.getItem('score') !== undefined ? sessionStorage.getItem('score') : 0;
        setScore(score);
        await postScoreData(score);
        sessionStorage.removeItem('score');

    };
    const postScoreData = async (score) => {
        const userDetails = getUserDetails();
        let module = sessionStorage.getItem('currentModule') !== undefined ? JSON.parse(sessionStorage.getItem('currentModule')) : {};
        if(module.course_id !== undefined && userDetails.token !== undefined && score != undefined){
            let score_type = 'pr_score';
            let module_type = 'pr_module_id';
            if(module.module_code === 'PO'){
                score_type = 'po_score';
                module_type = 'po_module_id';
            }
            let scoreData = {course_id: module.course_id, cohort_id: userDetails.user.cohort_id, module_id: module.module_id, score: score, score_type: score_type, module_type: module_type};
            let resp = await postScore(userDetails.token, scoreData);
            if(resp.error){
                toast.error("Error while adding score");
            }else{
            }
        }else{

        }
    };
    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
          ) : (
            <div className='pt-10'>
                <div className='lg:px-12 px-6 lg:pb-8 pb-8 border-b-2 border-[#D0D0D0]'>
                    <h3 className='text-2xl text-secondary inter-700 mb-3 lg:hidden'>{module.course_name}</h3>
                    <div className='beardcrumb-wrap mb-5'>
                        <ul className='flex flex-wrap items-center'>
                            <li className='prev-page text-sm lg:text-lg inter-400 relative lg:mr-6 mr-4'>
                                <a href='my-courses'>My Courses</a>
                            </li>
                            <li className='prev-page text-sm lg:text-lg inter-400 relative lg:mr-6 mr-4'>
                                <a href='classic-quantum'>{module.course_name}</a>
                            </li>
                            <li className='current-page text-sm lg:text-lg inter-400 relative lg:mr-6 mr-4'>
                                Module {module.module_seq_no}
                            </li>
                        </ul>
                    </div>
                    <div className='course-heading-wrap'>
                        {/* <h3 className='text-tertiary text-xl lg:text-2xl inter-500 mb-1'>Module 03</h3> */}
                        <h2 className='text-[#1D548D] text-3xl inter-500'>{module.module_desc}</h2>
                    </div>
                </div>
                {/* <div className='congrates-wrap flex justify-center items-center bg-center bg-no-repeat bg-cover'  style={{backgroundImage : "url('../../images/congrats-bg.webp')"}}>
                    <div>
                        <div className='text-center mb-20 2xl:mb-28'>
                            <h3 className='text-3xl text-white inter-700'>Congrats!</h3>
                            <p className='text-xl text-white inter-500'>You have successfully completed the { module.module_desc}.</p>
                        </div>
                        <div className='text-center mb-20 2xl:mb-28'>
                            <h3 className='text-4xl text-white inter-500 mb-3'>Your score is</h3>
                            <h2 className='text-5xl text-[#F9EA98] inter-700'>{score}/100</h2>
                        </div>
                        <div className='flex justify-center'>
                            <a href='/learner/courses' className='course-btn-yellow bg-[#E76457] hover:bg-primary flex items-center justify-center h-8 2xl:h-10 text-white inter-600 text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow px-10 py-5'>Continue Learning</a>
                        </div>
                    </div>
                </div> */}
                <div className='flex flex-wrap justify-center bg-gray-gradient px-6 py-6 md:px-10 lg:px-0 py-10'>
                    <div className='w-full md:w-1/3 mt-20'>
                        <div className='w-1/2 md:w-full lg:w-4/5 xl:w-1/2 mx-auto md:pr-8'>
                            <img className='w-full' src={score >= 70 ? '../images/test_pass.gif' : '../images/test_fail.gif'} alt=''/>
                        </div>
                    </div>
                    <div className='w-full md:w-2/3'>
                        <div className='text-center md:text-left mb-10 2xl:mb-20 mt-2'>
                            {module.module_code === 'PR' ? (
                                <>
                                <h3 className='text-3xl text-[#6E3174] inter-700'>Congratulations on completing the pre-assessment test!</h3>
                                <br></br>
                                <p className='text-base md:text-xl text-black inter-500 xl:w-4/5 lg:w-10/12'>This is a great first step in your learning journey. Your effort and dedication are commendable. Keep up the great work as you continue with the training module.</p>
                                <br></br>
                                <p className='text-base md:text-xl text-black inter-500 xl:w-4/5 lg:w-10/12'>Remember, every step you take brings you closer to being Quantum Literate. Keep pushing forward, You’ve got this!</p>
                                </>
   
                            ): (
                                <>
                                {score >= 70 ? (
                                    <>
                                        <h3 className='text-3xl text-[#6E3174] inter-700'>Congratulations on passing the post-assessment test!</h3>
                                        <br></br>
                                        <p className='text-base md:text-xl text-black inter-500 xl:w-4/5 lg:w-10/12'>Your hard work and dedication have truly paid off. This is a fantastic achievement and a testament to your commitment to becoming Quantum Literate. Keep up the great work as you continue to build on this success. </p>
                                        <br></br>
                                        <p className='text-base md:text-xl text-black inter-500 xl:w-4/5 lg:w-10/12'>Remember, this is just the beginning of many more accomplishments to come. Well done!</p>
                                    </>
                                ): (
                                    <>
                                        <p className='text-base md:text-xl text-black inter-500 xl:w-10/12 lg:w-10/12'>Thank you for completing the post-assessment test. Don’t be discouraged by your score, but remember, this is just one step in your journey to become Quantum Literate. </p>
                                        <br></br>
                                        <p className='text-base md:text-xl text-black inter-500 xl:w-10/12 lg:w-10/12'>Every attempt is an opportunity to learn and grow. Take a moment to review the areas where you had challenges and consider them as valuable lessons.</p>
                                        <br></br>
                                        <p className='text-base md:text-xl text-black inter-500 xl:w-10/12 lg:w-10/12'>With your determination and effort, I have no doubt you’ll overcome these hurdles and become Quantum Literate. Keep pushing forward, and don’t hesitate to review your weak areas.</p>
                                        <br></br>
                                        <p className='text-base md:text-xl text-black inter-500 xl:w-10/12 lg:w-10/12'>You’ve got this, and I believe in your success!</p>
                                    </>
                                )}
                                </>
                            )}
                            
                        </div>
                        <div className='text-center md:text-left mb-10 2xl:mb-28'>
                            <h3 className='text-4xl text-[#6E3174] inter-500 mb-3'>Your score is</h3>
                            <h2 className='text-5xl text-tertiary inter-700'>{score}/100</h2>
                        </div>
                        <div className='flex justify-center md:justify-start'>
                            <a onClick={() => navigate('/learner/courses')} className='cursor-pointer course-btn-yellow bg-[#E76457] hover:bg-black flex items-center justify-center h-8 2xl:h-10 text-white inter-600 text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow px-10 py-5'>Continue Learning</a>
                        </div>
                    </div>
                </div>

            </div>
          )}
        </div>
    );
}