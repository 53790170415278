import React, { useState, useEffect } from 'react';

const TextContent = ({ pedagogyValue }) => {
    const [fullView, setFullView] = useState(0);
    const resetFullView = () => {
        fullView === 0 ? setFullView(1) : setFullView(0);
    };


    return (
        <div>
            {pedagogyValue.pedagogy_type === 'TEXT' ? (
                <div className='lg:pt-10 pt-8 lg:px-12 px-6 pb-8 border-b-2 border-[#D0D0D0]'>
                    <h4 className='text-xl text-black inter-700 mt-4'>{pedagogyValue.title}</h4>
                    {pedagogyValue.text !== undefined && pedagogyValue.text !== '' && pedagogyValue.text !== null ? (
                        <>
                            {pedagogyValue.text.length > 300 ? (
                                <>
                                    {fullView === undefined || fullView === 0 ? (
                                        <p className='text-base inter-400 text-black mt-4'>
                                            {pedagogyValue.text.substring(0, 300)} &nbsp;&nbsp;&nbsp;
                                            {pedagogyValue.text.length <= 300 ? (
                                                null
                                            ): (
                                                <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show More</a>
                                            )}
                                        </p>
                                    ) : (
                                        <p className='text-base inter-400 text-black mt-4'>
                                            {pedagogyValue.text} &nbsp;&nbsp;&nbsp;
                                            <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show Less</a>
                                        </p>
                                    )}
                                </>
                            ): (
                                <p className='text-base inter-400 text-black mt-4'>
                                    {pedagogyValue.text}
                                </p>
                            )}
                            
                        </>
                    ) : (
                        null
                    )}
                </div>
            ) : (
                null
            )}
            {pedagogyValue.pedagogy_type === 'BULLET_TEXT' ? (
                <div className='lg:pt-10 pt-8 lg:px-12 px-6 pb-8 border-b-2 border-[#D0D0D0]'>
                    <h4 className='text-xl text-black inter-700 mt-4'>{pedagogyValue.title}</h4>
                    {pedagogyValue.text !== undefined && pedagogyValue.text !== '' && pedagogyValue.text !== null ? (
                        <>
                            {fullView === undefined || fullView === 0 ? (
                                <p className='text-base inter-400 text-black mt-4'>
                                    {pedagogyValue.text.substring(0, 300)} &nbsp;&nbsp;&nbsp;
                                    <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show More</a>
                                </p>
                            ) : (
                                <p className='text-base inter-400 text-black mt-4'>
                                    {pedagogyValue.text} &nbsp;&nbsp;&nbsp;
                                    <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show Less</a>
                                </p>
                            )}
                        </>
                    ) : (
                        null
                    )}
                    {pedagogyValue.json_data !== undefined && pedagogyValue.json_data !== '' && pedagogyValue.json_data !== null ? (
                        <>
                            {pedagogyValue.json_data.map((bulletValue, bulletIndex) => (
                                <ul className="bullet-list">
                                    <li key={bulletIndex}>
                                       <h4 className='text-xl text-black inter-700 mt-4'>{bulletValue.item}</h4> 
                                       <p>{bulletValue.value}</p>
                                    </li>
                                </ul>

                            ))}
                        </>
                    ) : (
                        null
                    )}
                </div>
            ) : (
                null
            )}
        </div>
    );
};

export default TextContent;