import React, { useState, useEffect } from 'react';
import { login, forgotChangePassword, generatePassword, clearStore } from '../services/UserService';
import '../spinner.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function Maintenance() {
    useEffect(() => {
        document.documentElement.style.setProperty('--primary-color', '#C13F63');
        document.documentElement.style.setProperty('--secondary-color', '#366494');
        document.documentElement.style.setProperty('--tertiary-color', '#58C181');
    
        clearStore();
    },[]);
    return (
        <div className='flex items-center justify-center h-screen md:px-0 px-6'>
            <div className=''>
                <div className='mantince-icon w-44 md:w-72 mx-auto mb-8'>
                    <img className='' src='../images/maintenance.webp' alt='maintenance icon'/>
                </div>
                <div className='mantince-text text-center'>
                    <h3 className='text-4xl inter-700 text-black mb-4'>Website Under Maintenance</h3>
                    <p>
                        Our website is currently undergoing scheduled maintenance.<br /> We should be back shortly. Thank you for your patience.
                    </p>
                    <div className='flex items-center justify-center flex-wrap mt-8 2xl:mt-10'>
                        <a href='back' className='w-full md:w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-10 2xl:h-16 text-white inter-500 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back to Dashboard</a>
                    </div>
                </div>
            </div>
        </div>
    );
}