import React, { useState, useEffect} from 'react';
import '../../spinner.css';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getReportData, getDetailedReportData } from '../../services/LearnerReportService';

export default function LearnerScore() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState({total_question_count: 0,
        right_question_count: 0,
        wrong_question_count: 0,
        right_per: 0,
        lesson_completed: 0,
        test_completed: 0,
        total_time: 0
    });
    const [detailedReport, setDetailedReport] = useState([]);
    const [learningTime, setLearningTime] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            getReport();
            getDetailedReport();
        }
    },[]);

    const getReport = async () => {
        const userDetails = getUserDetails();
        setLoading(true);
        const reportData = await getReportData(userDetails.token);
        if(!reportData.error){
            setReport(reportData.data);
            convertSeconds(reportData.data.total_time);
        }else{
            toast.error(reportData.data);
        } 
        setLoading(false);   
        
    };

    const getDetailedReport = async () => {
        const userDetails = getUserDetails();
        setLoading(true);
        const detailedReportData = await getDetailedReportData(userDetails.token);
        if(!detailedReportData.error){
            setDetailedReport(detailedReportData.data);
        }else{
            toast.error(detailedReportData.data);
        } 
        setLoading(false);   
        
    }; 

    const convertSeconds = (seconds) => {
        const days = Math.floor(seconds / (24 * 3600));
        seconds = seconds % (24 * 3600);
        const hours = Math.floor(seconds / 3600);
        seconds = seconds % 3600;
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        setLearningTime({days: days, hours: hours, minutes: minutes, seconds: remainingSeconds});
    };

    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
            ) : (
                <>
                    <div className='py-10 lg:px-12 pl-6 pr-6'>
                        <h3 className='text-2xl text-secondary text-center inter-700 mb-3 md:mb-4 lg:mb-3 lg:hidden'>Reports</h3>
                        <div className='flex flex-wrap'>
                            <div className='w-full md:w-1/2'>
                                <div className='flex mb-3 lg:mb-4 xl:mb-6 2xl:mb-8'>
                                    <div className='w-3/4 pr-6 lg:pr-10 2xl:pr-12'>
                                        <h3 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-secondary inter-700 md:text-right">Overall Score</h3>
                                    </div>
                                    <div className='w-1/4'>
                                        <p className='text-xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl text-tertiary inter-700'>{Math.ceil(report.right_per)}%</p>
                                    </div>
                                </div>
                                <div className='flex mb-3 lg:mb-4 xl:mb-6 2xl:mb-8'>
                                    <div className='w-3/4 pr-6 lg:pr-10 2xl:pr-12'>
                                        <h3 className="text-xl lg:text-2xl xl:text-2xl 2xl:text-3xl text-black inter-400 md:text-right">Modules Completed</h3>
                                    </div>
                                    <div className='w-1/4'>
                                        <p className='text-xl lg:text-2xl xl:text-2xl 2xl:text-3xl text-tertiary inter-700'>{report.lesson_completed}</p>
                                    </div>
                                </div>
                                <div className='flex mb-3 xl:mb-0'>
                                    <div className='w-3/4 pr-6 lg:pr-10 2xl:pr-12'>
                                        <h3 className="text-xl lg:text-2xl xl:text-2xl 2xl:text-3xl text-black inter-400 md:text-right">Tests Completed</h3>
                                    </div>
                                    <div className='w-1/4'>
                                        <p className='text-xl lg:text-2xl xl:text-2xl 2xl:text-3xl text-tertiary inter-700'>{report.test_completed}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full md:w-1/2 flex justify-center'>
                                <div onClick={() => navigate('/learner/certificate')} className='flex xl:flex-col items-center cursor-pointer'>
                                    <div className='mt-2 xl:mt-0 2xl:mb-1'>
                                        <img className='w-20 lg:w-28 xl:32 2xl:w-36' src='../images/download-cert.svg' alt='Certificate'/>
                                    </div>
                                    <p className='text-2xl lg:text-3xl xl:text-xl 2xl:text-2xl text-[#E76457] inter-600 text-left xl:text-center xl:leading-6 ml-4 xl:ml-0'>Download <br />Certificates</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='answer-wrap flex'>
                        <div className='w-1/2 py-10 lg:pl-12 pl-6 bg-[#F6F6F6] flex justify-center'>
                            <div className='w-full md:w-1/2 lg:w-1/3'>
                                <h3 className='text-4xl text-[#E76457] inter-700'>{report.wrong_question_count}</h3>
                                <p className='text-xl xl:text-2xl text-black inter-400'>Wrong Answers</p>
                            </div>
                        </div>
                        <div className='w-1/2 py-10 md:pl-0 pl-2 lg:pr-12 pr-6 bg-[#F2F1F1] flex justify-center'>
                            <div className='w-full md:w-1/2 lg:w-1/3'>
                                <h3 className='text-4xl text-[#58C181] inter-700'>{report.right_question_count}</h3>
                                <p className='text-xl xl:text-2xl text-black inter-400'>Correct Answers</p>
                            </div>
                        </div>
                    </div>
                    <div className='py-10 lg:px-12 md:pl-6 pl-0 md:pr-6 pr-0'>
                        <div className='course-slider-row flex overflow-hidden'>
                            <div className='course-slider score-course-slider overflow-hidden md:pt-12 w-full'>
                                <Swiper
                                    className='md:pl-0 pl-6 md:pr-0 pr-6'
                                    spaceBetween={10}
                                    slidesPerView={1.5}
                                    freeMode={true}
                                    navigation={true}
                                    modules={[Navigation, FreeMode]}
                                    breakpoints={{
                                    640: {
                                        slidesPerView:1.5,
                                        spaceBetween:10,
                                    },
                                    768: {
                                        slidesPerView:3.5,
                                        spaceBetween:10,
                                    },
                                    1024: {
                                        slidesPerView:4.5,
                                        spaceBetween:20,
                                    },
                                    1400: {
                                        slidesPerView:5.5,
                                    },
                                    }}
                                >
                                {detailedReport.map((value, index) => (
                                    <div key={"key"+index} className=''>
                                        <SwiperSlide>
                                            <div className='course-card flex flex-col justify-between bg-[#6E3174] min-h-52 2xl:min-h-[230px]  rounded-3xl py-6 lg:pl-5 xl:pl-7 pl-5 lg:pr-5 xl:pr-10 pr-5 block hover:scale-105 hover:-translate-y-4 hover:shadow-lg transition-all duration-300 ease-in-out'>
                                                <h2 className='text-2xl lg:text-2xl 2xl:text-3xl text-white inter-700 mb-4'>{value.course_name}</h2>
                                                <div>
                                                    <p className='lg:text-base 2xl:text-lg text-white inter-600'>#{value.module_seq_no} / {value.module_name === 'Post Assessment' ? "Post-Test" : "Pre-Test"}</p>
                                                    <p className='lg:text-lg 2xl:text-xl text-white inter-600'>Score {Math.ceil(parseInt(value.right_question_count) * 100 / parseInt(value.total_question_count))} %</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </div>  
                                ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap border-y-2 border-[#D0D0D0]'>
                        <div className='w-full md:w-2/5 flex md:justify-center py-10 lg:pl-12 pl-6'>
                            <div className='md:w-3/4 xl:w-1/2 flex items-center'>
                                <img className='w-12 md:w-16' src='../images/note-time.svg' alt='note time'/>
                                <h3 className='text-3xl text-[#C13F63] inter-700 ml-4'>Learning Time</h3>
                            </div>
                        </div>
                        <div className='w-1/3 md:w-1/5 py-10 bg-[#EBE6E6] flex justify-center items-center'>
                            <div className='text-center'>
                                <h3 className='text-4xl text-black inter-700'>{learningTime.days}</h3>
                                <p className='text-xl text-black inter-400'>Days</p>
                            </div>
                        </div>
                        <div className='w-1/3 md:w-1/5 py-10 bg-[#F2F1F1] flex justify-center items-center'>
                            <div className='text-center'>
                                <h3 className='text-4xl text-black inter-700'>{learningTime.hours}</h3>
                                <p className='text-xl text-black inter-400'>Hours</p>
                            </div>
                        </div>
                        <div className='w-1/3 md:w-1/5 py-10 bg-[#EBE6E6] flex justify-center items-center'>
                            <div className='text-center'>
                                <h3 className='text-4xl text-black inter-700'>{learningTime.minutes}</h3>
                                <p className='text-xl text-black inter-400'>Minutes</p>
                            </div>
                        </div>
                    </div>
                    <div className='py-10 lg:px-12 pl-6 pr-6'>
                    <div className='flex justify-center lg:justify-start'>
                        <a href="overall" className='bg-[#58C181] hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back to Dashboard</a>
                        </div>
                    </div>
                </>
            )
          }
        </div>
    );
}