import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../services/UserService';
import { toast } from 'react-toastify';

export default function SANoAccess() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
          setUserDetails(userDetails);
        }
    },[]);

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
            <div className='lg:py-10 lg:px-24 xl:px-32 py-10 px-10'>
                {/* <h3 className='text-2xl text-secondary text-center inter-700 mb-6 md:mb-4 lg:mb-3 lg:hidden'>Invite New Learner</h3> */}
                <div className='request-page-wrap flex justify-center items-center'>
                    <div className=''>
                        <h3 className='text-xl md:text-2xl text-tertiary inter-700 text-center'>You Do not have access to this page.</h3>
                        <div className='flex items-center justify-center mt-12 md:mt-20 2xl:mt-28'>
                            <button onClick={() => navigate('/superadmin/dashboard')} type="button" className='w-full md:w-auto bg-primary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back to Dashboard</button>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}