import { API_URL } from "../utility/constants";

export async function changePassword(changePassword, token) {
    try{
        const response = await fetch(API_URL+`users/profile/changePassword`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(changePassword)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
    
};

export async function getUserProfile(token) {
    try{
        const response = await fetch(API_URL+"users/profile/getUserProfile", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function updateProfile(profile, token) {
    try{
        const response = await fetch(API_URL+`users/profile/updateProfile`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(profile)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
    
};