import { API_URL } from "../utility/constants";


export async function getAllSubscriptions(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminOrder/getAllSubscriptions", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCourseSubscriptions(token, course_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminOrder/getCourseSubscriptions?course_id="+course_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};


export async function getAllOrders(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminOrder/getAllOrders", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addSubscription(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminOrder/addSubscription`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updateSubscription(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminOrder/updateSubscription`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};