import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { toast } from 'react-toastify';
import { getCustomerAdminReport } from '../../services/SuperAdminReportService';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { getRandomHexColor } from '../../services/CommonService';
import { getAllAppAdmin } from '../../services/AppAdminService';
import Select from 'react-select';
import {formatSixDigitNumber, capitalizeFirstLetter} from '../../services/CommonService';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);


export default function SuperAdminCustomerAdminReport() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    // const [customerData, setCustomerData] = useState([]);
    // const [customerReport, setCustomerReport] = useState({
    //     labels: [],
    //     datasets: [
    //       {
    //         label: 'Number Of Learner',
    //         data: [],
    //         backgroundColor: [],
    //         borderColor: 'rgba(75, 192, 192, 1)',
    //         borderWidth: 1,
    //       },
    //     ],
    // });
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    // Chart options
    // const options = {
    //     responsive: true,
    //     plugins: {
    //       legend: {
    //         position: 'top',
    //       },
    //       tooltip: {
    //         callbacks: {
    //             label: function(context) {
    //                 const index = context.dataIndex;
    //                 let customer_name = customerData[index].name;
    //                 let subscription_code = customerData[index].subscription_code;
    //                 let num_of_admins = customerData[index].num_of_admins === undefined ? 0 : customerData[index].num_of_admins;
    //                 let subscription_end_date = customerData[index].subscription_end_date;
    //                 let paid_amount = customerData[index].paid_amount === undefined ? 0 : customerData[index].paid_amount;
    //                 let total_amount = customerData[index].total_amount === undefined ? 0 : customerData[index].total_amount;

    //                 return [
    //                         "Name: "+customer_name,
    //                         "Subscription: "+subscription_code,
    //                         "Admins: "+ num_of_admins,
    //                         "Subscription End Date: "+ subscription_end_date,
    //                         "Paid Amount: "+ paid_amount,
    //                         "Total Amount: "+ total_amount,

    //                     ]
    //             },
    //             title: function() {
    //                 return 'Detailed Data';
    //             }
    //         },
    //       },
    //     },
    // };
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            getAllAppAdminData(userDetails.token);
        }
    },[]);

    const getAllAppAdminData = async (token) => {
        setLoading(true);
        const usersData = await getAllAppAdmin(token);
        if(usersData.error){
            setUsers([]);
        }else{
            let admins = [];
            for(let i=0; i< usersData.data.length; i++){
                    let admin = Object.assign(usersData.data[i]);
                    admin['uid'] = "UID" + formatSixDigitNumber(admin.id);
                    admin['name'] = (admin.first_name !== undefined && admin.first_name !== null ? admin.first_name : "")
                    + " " + (admin.middle_name !== undefined && admin.middle_name !== null ? admin.middle_name: "")
                    + " " + (admin.last_name !== undefined && admin.last_name !== null ? admin.last_name: "");
                    admin['statusInLowerCase'] = capitalizeFirstLetter(admin.status);
                    admin['label'] = admin['name'];
                    admin['value'] = admin['id'];
                    admins.push(admin);


                
            }
            setUsers(admins);
            if(admins.length > 0){
                await handleAppAdminChange(admins[0]);
            }
        }
        setLoading(false);
    };

    const handleAppAdminChange = async (appAdmin) => {
        setLoading(true);
        setSelectedUser(appAdmin);
        setLoading(false); 
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='py-10 lg:px-12 px-6'>
                    <div className='back-btn flex justify-start mb-5 md:mb-8'>
                        <a onClick={() => navigate('/superadmin/superadminreport')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div>
                    <h3 className='text-2xl text-secondary inter-700 lg:hidden mb-4'>Customer Admin Report</h3>
                    <div className=''>
                        <div className='md:w-1/2 mx-auto'>
                            <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Select Super Admin</label>
                            <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md relative z-10'
                                options={users}
                                value={users.find(option => option.value === selectedUser.id)}
                                onChange={(values) => handleAppAdminChange(values)}
                            />
                            {/* <select className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2 2xl:py-5 px-6 2xl:px-10 transition duration-300'>
                                <option value="select-super-admin">Select Super Admin</option>
                                <option value="super-admin-one">Super Admin One</option>
                                <option value="super-admin-three">Super Admin Two</option>
                            </select> */}

                        </div>
                        <div class="cohort-grade-card-wrap flex flex-wrap justify-center mt-8">
                            <div class="cohort-grade-card-sngl text-center rounded-3xl lg:p-10 p-8 w-full md:w-2/5">
                                <h3 class="text-3xl text-white inter-700 mb-7">{selectedUser.uid} - {selectedUser.name}</h3>
                                <div class="mb-6">
                                    <h4 class="text-2xl text-white inter-700">{selectedUser.email}</h4>
                                    <p class="text-base 2xl:text-lg text-white inter-400 leading-none">Email</p>
                                </div>
                                <div class="mb-6">
                                    <h4 class="text-2xl text-white inter-700">{selectedUser.phone_no}</h4>
                                    <p class="text-base 2xl:text-lg text-white inter-400 leading-none">Phone</p>
                                </div>
                                <div class="mb-6">
                                    <h4 class="text-2xl text-white inter-700">{selectedUser.country}</h4>
                                    <p class="text-base 2xl:text-lg text-white inter-400 leading-none">Country</p>
                                </div>
                                <div class="mb-6">
                                    <h4 class="text-2xl text-white inter-700">{selectedUser.state}</h4>
                                    <p class="text-base 2xl:text-lg text-white inter-400 leading-none">State</p>
                                </div>
                                <div class="mb-0">
                                    <h4 class="text-2xl text-white inter-700">{selectedUser.status}</h4>
                                    <p class="text-base 2xl:text-lg text-white inter-400 leading-none">Status</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            )}
        </div>
    );
}