import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getAllModules, getPedagogy, deletePedagogy } from '../../services/CourseService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


export default function PreviewPedagogy() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [selectedModule, setSelectedModule] = useState({});
    const [pedagogy, setPedagogy] = useState([]);

    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            let moduleValue = sessionStorage.getItem('selectedModule') === null ? null : JSON.parse(sessionStorage.getItem('selectedModule'));
            if(moduleValue === null){
                navigate('/superadmin/managepedagogy');
                toast.warning("Please choose a course and module!");
            }else{
                setSelectedModule(moduleValue);
                // sessionStorage.removeItem('selectedModule');
            }
            let pedagogyPreview = sessionStorage.getItem('pedagogyPreview') === null ? null : JSON.parse(sessionStorage.getItem('pedagogyPreview'));
            if(pedagogyPreview === null){
                navigate('/superadmin/managepedagogy');
                toast.warning("No assessment to show!");
            }else{
                setPedagogy(pedagogyPreview);
                sessionStorage.removeItem('pedagogyPreview');
            }
            
        }
    },[]);

    const editPedagogy = (pd) => {
        sessionStorage.setItem('pedagogyEdit', JSON.stringify(pd));
        sessionStorage.setItem('moduleValue', JSON.stringify(selectedModule));
        navigate('/superadmin/addpedagogy')
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='py-10 lg:px-12 px-6'>
                    <div className=''>
                        <div className='back-btn flex mb-4 md:mb-6'>
                            <a onClick={() => navigate('/superadmin/managepedagogy')} className='cursor-pointer w-auto md:auto mb-4 md:mb-0 bg-tertiary hover:bg-black flex items-center justify-center px-6 h-8 2xl:h-12 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                        </div>
                        <h3 className='text-2xl text-secondary inter-700 mb-3 lg:hidden'>Module Preview</h3>
                        <div className='course-heading-wrap'>
                            <h2 className='text-[#1D548D] text-2xl md:text-3xl inter-400 mb-3'>{selectedModule.course_name}</h2>
                            <h3 className='text-tertiary text-xl lg:text-2xl inter-400 mb-3'>Lesson {selectedModule.module_seq_no}</h3>
                            <h2 className='text-[#1D548D] text-lg inter-400 mb-3'>{selectedModule.module_name}</h2>
                        </div>
                        <div className=''>
                            {pedagogy.map((pd, index) => (
                                <>
                                    {pd.pedagogy_type === 'IMAGE' ? (
                                        <>
                                        <p className='text-lg inter-700 text-black mt-8'>{pd.title !== null && pd.title !== undefined ? pd.title : ""}</p>
                                        <div className='image-box mt-8 mb-12'>
                                            <div className='-mx-6 md:mx-0'>
                                                <img className='w-full' src={pd.url} alt='' />
                                            </div>
                                        </div>
                                        <p className='text-lg inter-400 text-black mb-4 mt-10'>
                                            {pd.text !== null && pd.text !== undefined ? pd.text : ""}
                                        </p>
                                        <div className='flex'>    
                                            <a onClick={() => editPedagogy(pd)} className='cursor-pointer w-auto md:w-auto bg-[#E76457] hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Edit</a>
                                        </div>
                                        </>
                                    ) : (null)}
                                    {pd.pedagogy_type === 'VIDEO' ? (
                                        <>
                                        <p className='text-lg inter-700 text-black mt-8'>{pd.title !== null && pd.title !== undefined ? pd.title : ""}</p>
                                        <div className='video-sec md:w-full mt-8 mb-12'>
                                            <div className='video-box -mx-6 md:mx-0'>
                                                <video controls>
                                                    <source src={pd.url} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>                                            
                                            </div>
                                        </div>
                                        <p className='text-lg inter-400 text-black mb-4 mt-10' dangerouslySetInnerHTML={{ __html: pd.text }} />
                                        <div className='flex'>    
                                            <a onClick={() => editPedagogy(pd)} className='cursor-pointer w-auto md:w-auto bg-[#E76457] hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Edit</a>
                                        </div>
                                        </>
                                    ) : (null)}
                                    {pd.pedagogy_type === 'AUDIO' ? (
                                        <>
                                        <p className='text-lg inter-700 text-black mt-8'>{pd.title !== null && pd.title !== undefined ? pd.title : ""}</p>
                                        <div className='audio-box mt-8 mb-12'>
                                            <p className='text-2xl inter-400 text-black mt-2 mb-4'>Click play icon to <br />
                                                Listen to the audio of Pro. Albert Einstein
                                            </p>
                                        </div>
                                        <p className='text-lg inter-400 text-black mb-4 mt-10'>
                                            {pd.text !== null && pd.text !== undefined ? pd.text : ""}
                                        </p>
                                        <div className='flex'>    
                                            <a onClick={() => editPedagogy(pd)} className='cursor-pointer w-auto md:w-auto bg-[#E76457] hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Edit</a>
                                        </div>
                                        </>
                                    ) : (null)}
                                    
                                </>
                            ))}
                            
                            {/* <div className='summry-box mt-12'>
                                <p className='text-lg inter-700 text-black mt-2 mb-4'>Summary </p>
                                <p className='text-lg inter-400 text-black mb-4'>Artificial Intelligence (AI) and Machine Learning (ML) are transformative technologies with applications spanning various sectors, revolutionizing how we interact with and utilize data. AI refers to the simulation of human intelligence in machines designed to think and act like humans, while ML, a subset of AI, involves the development of algorithms that allow computers to learn from and make predictions based on data.</p>
                                <div className='flex'>    
                                    <a href='edit' className='w-auto md:w-auto bg-[#E76457] hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Edit</a>
                                </div>
                            </div>
                            <div className='md:w-3/5 lg:w-2/5 mt-12'>
                                <div>
                                    <a href='preview' className='w-full md:w-auto mb-4 md:mb-0 bg-secondary hover:bg-black flex items-center justify-center text-center md:px-6 h-10 2xl:h-16 text-white inter-400 md:inter-700 text-base 2xl:text-2xl leading-none rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Preview of Quiz/ Test/ Games / Simulation</a>
                                </div>
                                <div className='flex justify-between flex-wrap md:mt-8'>
                                    <a href='publish' className='w-full md:w-2/5 mb-4 md:mb-0 bg-tertiary hover:bg-black flex items-center justify-center px-6 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Publish Module</a>
                                    <a href='save' className='w-full md:w-2/5 mb-4 md:mb-0 bg-[#E76457] hover:bg-black flex items-center justify-center px-6 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Save as Draft</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};