import { API_URL } from "../utility/constants";

export async function getSuperAdminReport(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminReport/getSuperAdminReportData", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getSuperAdminDetailedReport(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminReport/getSuperAdminDetailedReportData", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getSuperAdminAllCohortEvaluationReport(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminReport/getSuperAdminAllCohortEvaluationReport", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getSuperAdminAllCohorts(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminReport/getSuperAdminAllCohorts", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAllCohortsWithCustomer(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCohorts/getAllCohorts", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getSuperAdminCohortEvaluationReport(token, cohort_id, customer_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminReport/getSuperAdminCohortEvaluationReport?cohort_id="+cohort_id+"&customer_id="+customer_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getSuperAdminCohortLearnerReport(token, cohort_id, customer_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminReport/getSuperAdminCohortLearnerReport?cohort_id="+cohort_id+"&customer_id="+customer_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getSuperAdminLearningSummary(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminReport/getSuperAdminLearningSummary", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getSuperAdminLearningDetailSummary(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminReport/getSuperAdminLearningDetailSummary", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getSuperAdminLearningDetail(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminReport/getSuperAdminLearningDetail", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCustomerReport(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminReport/getCustomerReport", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCustomerAdminReport(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminReport/getCustomerAdminReport", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAvgScore(token, data) {
    try{
        const response = await fetch(API_URL+"superAdmin/superAdminAnalytics/getAvgScore", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getLearningTime(token, data) {
    try{
        const response = await fetch(API_URL+"superAdmin/superAdminAnalytics/getLearningTime", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getMaxScore(token, data) {
    try{
        const response = await fetch(API_URL+"superAdmin/superAdminAnalytics/getMaxScore", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getMinScore(token, data) {
    try{
        const response = await fetch(API_URL+"superAdmin/superAdminAnalytics/getMinScore", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAttendance(token, data) {
    try{
        const response = await fetch(API_URL+"superAdmin/superAdminAnalytics/getAttendance", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

