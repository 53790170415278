import React, { useState, useEffect} from 'react';
import '../../spinner.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import {Chart, ArcElement, Tooltip, Legend, Title} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import { getSuperAdminLearningDetail, getSuperAdminLearningSummary, getSuperAdminLearningDetailSummary } from '../../services/SuperAdminReportService';

Chart.register(ArcElement, Tooltip, Legend, Title);


export default function SuperAdminLearningReport() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [courseConsumedReport, setCourseConsumedReport] = useState({});
    const [overviewReport, setOverviewReport] = useState({});
    const [courseReport, setCourseReport] = useState([]);
    const [colors, setColors] = useState(['Red', 'Blue', 'Yellow', 'Green', 'Pink', 'Orange', 'Grey', 'Violet', 'Turquoise']);
    const [data, setData] = useState({
      labels: [],
      datasets: [{
          label: 'Dataset',
          data: [],
          backgroundColor: [],
          hoverOffset: 4
      }]
    });

    const options = {
      plugins: {
          tooltip: {
              callbacks: {
                  label: function(context) {
                      const index = context.dataIndex;
                      let course_name = courseReport[index].course_name;
                      let module_count = courseReport[index].module_count;
                      let total_comp_std = courseReport[index].total_comp_std;
                      let total_learning_time = courseReport[index].total_learning_time;
                      let total_std = courseReport[index].total_std;
                      return [course_name, module_count + " Modules", total_std + " Students", total_comp_std + " Students Completed", Math.ceil(total_learning_time/60) + " Minutes Spent"]
                  },
                  title: function() {
                      return 'Detailed Data';
                  }
              },
              useHTML: true
          }
      }
    };

    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
          getAllData(userDetails.token);
          Chart.defaults.plugins.tooltip.backgroundColor = 'rgb(0, 0, 156)';
          Chart.defaults.plugins.legend.position = 'bottom';
          Chart.defaults.plugins.legend.title.display = true;
          Chart.defaults.plugins.legend.title.text = 'Learning Data';
          Chart.defaults.plugins.legend.title.font = 'Helvetica Neue';

        }
    },[]);
    const getAllData = async (token) => {
        setLoading(true);
        await getCourseReport(token);
        await getOverviewReport(token);
        await getCourseConsumedReport(token);
        setLoading(false);
    };

    const getCourseReport = async (token) => {
        let resp = await getSuperAdminLearningDetail(token);
        if(resp.error === false){
          setCourseReport(resp.data);
          let chartData = resp.data;
          let labels = [];
          let dataValues = [];
          let bgColors = [];
          
          for(let i=0; i<chartData.length; i++){
            if(chartData[i].total_learning_time !== undefined && chartData[i].total_learning_time !== null){
              labels.push(chartData[i].course_name);
              dataValues.push(parseInt(chartData[i].total_learning_time));
              bgColors.push(colors[i]);
            }
          }
          let newData = {
            labels: labels,
            datasets: [{
                label: 'Dataset',
                data: dataValues,
                backgroundColor: bgColors,
                hoverOffset: 4
            }]
          };
          setData(newData);
        }
    };

    const getOverviewReport = async (token) => {
      let resp = await getSuperAdminLearningDetailSummary(token);
      if(resp.error === false){
        setOverviewReport(resp.data);
      }
    };

    const getCourseConsumedReport = async (token) => {
      let resp = await getSuperAdminLearningSummary(token);
      if(resp.error === false){
        setCourseConsumedReport(resp.data);
      }
    };


    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
            ) : (
                <>
                    <div className='pt-10 lg:px-12 px-6 pb-12'>
                      <div className='back-btn flex justify-start mb-6 md:mb-6'>
                          <a onClick={() => navigate('/superadmin/superadminreport')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                      </div>
                      <h3 className='text-2xl text-secondary inter-700 lg:hidden mb-4'>Learning Report</h3>
                      <div className='report-card-row flex'>
                          <div className='w-full'>
                              <div className='cohort-big-card rounded-3xl lg:p-10 p-4 md:p-8 mb-8'>
                                  <h2 className='text-3xl text-white inter-700 mb-4'>Summary</h2>
                                  <div className='flex items-center flex-wrap'>
                                      <div className='w-full md:w-1/2'>
                                          <div className='cohort-big-card-left'>
                                              <div className='mb-5 md:mb-6'>
                                                  <h3 className='text-2xl text-white inter-700'>{courseConsumedReport.max_course_name} | {Math.ceil(courseConsumedReport.max_per)}%</h3>
                                                  <p className='text-base 2xl:text-lg text-white inter-400'>Most Consumed Course</p>
                                              </div>
                                              <div className=''>
                                                  <h3 className='text-2xl text-white inter-700'>{courseConsumedReport.min_course_name} | {Math.ceil(courseConsumedReport.min_per)}%</h3>
                                                  <p className='text-base 2xl:text-lg text-white inter-400'>Least Consumed Course </p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className='w-full md:w-1/2 mt-5 md:mt-0'>
                                          <div className='cohort-big-card-right'>
                                              <p className='text-xl text-white inter-400 mb-2 md:mb-3'><span className='inter-700'>{overviewReport.completedCourseCount}</span> Courses Completed</p>
                                              <p className='text-xl text-white inter-400 mb-2 md:mb-3'><span className='inter-700'>{overviewReport.completedModuleCount}</span> Modules Completed </p>
                                              <p className='text-xl text-white inter-400 mb-2 md:mb-3'><span className='inter-700'>{overviewReport.totalCompletedTest}</span> Tests Completed </p>
                                              <p className='text-xl text-white inter-400 md:mb-0'><span className='inter-700'>{Math.ceil(overviewReport.totalLearningSec/60)}</span> Minutes Spent </p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className='report-graph flex justify-center'>
                                  <div className='w-full lg:w-2/5 bg-[#F1EAEA] rounded-3xl p-6'>
                                      <Doughnut data={data} options={options} />
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                  </div>
                </>
            )
          }
        </div>
    );
}