import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getCohortCoursesData, addCohortCourseData, updateCohortCourseData, getCustomerCoursesData } from '../../services/SuperAdminCohortService';
import { toast } from 'react-toastify';

export default function SuperAdminCohortCourse() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [customerCourses, setCustomerCourses] = useState([]);
    const [cohort, setCohort] = useState({cohort_code: "", cohort_desc: "", passing_per: 70, status: 'ACTIVE', org_name: '', org_type: '', address: '', city: '', state: '', zip_code: '', contact_person: '', contact_number: ''});
    const [cohortCourses, setCohortCourses] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
          setUserDetails(userDetails);
          let cohortData = sessionStorage.getItem('cohortCourseEdit') !== undefined ? JSON.parse(sessionStorage.getItem('cohortCourseEdit')) : {};
          if(cohortData !== null && cohortData !== undefined && cohortData.cohort_code !== undefined){
            cohortData['id'] = cohortData['cohort_id'];
            setSelectedCustomer(cohortData["customer_id"]);
            setCohort(cohortData);
            sessionStorage.removeItem('cohortCourseEdit');
            getCustomerCourses(userDetails.token, cohortData["customer_id"]);
            getCohortCourses(userDetails.token, cohortData.cohort_id);
          }else{
            navigate('/superadmin/managecohort');
            toast.info("Please choose cohort in manage cohort page.")
          }
        }
    },[]);

    const getCustomerCourses = async (token, customer_id) => {
        const customerCourseData = await getCustomerCoursesData(token, customer_id);
        if(!customerCourseData.error){
            setCustomerCourses(customerCourseData.data);
        }
    };

    const getCohortCourses = async (token, cohort_id) => {
        const cohortCourseData = await getCohortCoursesData(token, cohort_id);
        if(!cohortCourseData.error){
            let cohortCourseObj = {};
            for(let i=0; i< cohortCourseData.data.length; i++){
                cohortCourseObj[cohortCourseData.data[i].id] = cohortCourseData.data[i];
            }
            setCohortCourses(cohortCourseObj);
        }
    };

    const handleValueChange = async (course_id) => {
        const userDetails = getUserDetails();
        if(cohortCourses[course_id]===undefined){
            let dataToPost = {course_id: course_id, cohort_id: cohort.id, course_seq_no: 1, customer_id: selectedCustomer};
            await addCohortCourse(userDetails.token, dataToPost);
        }else{
            let cohortCourse = cohortCourses[course_id];
            let newStatus = cohortCourse["cohort_course_status"] === 'ACTIVE' ? "INACTIVE" : "ACTIVE";
            let dataToPost = {id: cohortCourse["cohort_course_id"], course_id: course_id, cohort_id: cohortCourse["cohort_id"], course_seq_no: cohortCourse['course_seq_no'], status: newStatus, customer_id: selectedCustomer};
            await updateCohortCourse(userDetails.token, dataToPost)
        }
    };

    const addCohortCourse = async (token, data) => {
        setLoading(true);
        let resp = await addCohortCourseData(token, data);
        if(!resp.error){
            toast.success("Cohort Permission changed!");
            await getCohortCourses(token, cohort.id);
        }else{
            toast.error("Cohort Permission not changed!");
        }
        setLoading(false);
    };

    const updateCohortCourse = async (token, data) => {
        setLoading(true);
        let resp = await updateCohortCourseData(token, data);
        if(!resp.error){
            toast.success("Cohort Permission changed!");
            await getCohortCourses(token, cohort.id);
        }else{
            toast.error("Cohort Permission not changed!");
        }
        setLoading(false);
    };

    

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='pt-10 lg:px-12 px-6 pb-12'>
                    <h3 className='text-2xl text-secondary inter-700 lg:hidden mb-4'>Cohort Permissions</h3>
                    <div className='back-btn flex justify-start mb-5 md:mb-8'>
                        <a onClick={() => navigate('/superadmin/managecohort')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div>
                    <div className=''>
                        <h3 className='text-xl md:text-3xl text-secondary inter-700 mb-4 md:mb-8'>Cohort: {cohort.cohort_desc + " - " + (cohort.org_name !== undefined && cohort.org_name !== null ? cohort.org_name: "")}</h3>
                        <form>
                            {customerCourses.map((value, index) => (
                                <div key={index} className='permisn-sngl mb-6'>
                                    <div className='flex'>
                                        <div className='icon-radio-button flex items-center relative text-black hover:text-tertiary'>
                                            <input className='absolute opacity-0 cursor-pointer w-8 h-8 md:w-10 md:h-10 z-20' type='checkbox' name='checkbox' id='photonics' value={value.id} checked={cohortCourses[value.id] !== undefined && cohortCourses[value.id].cohort_course_status === 'ACTIVE'} onChange={() => handleValueChange(value.id)} />
                                            <div className='radio-icon-box cursor-pointer relative z-10'>
                                                <svg className='mx-auto w-8 h-8 md:w-10 md:h-10 transition duration-300' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.416 8.33317C9.26542 8.33317 8.33268 9.26591 8.33268 10.4165V39.5832C8.33268 40.7338 9.26542 41.6665 10.416 41.6665H39.5827C40.7333 41.6665 41.666 40.7338 41.666 39.5832V10.4165C41.666 9.26591 40.7333 8.33317 39.5827 8.33317H10.416ZM4.16602 10.4165C4.16602 6.96472 6.96424 4.1665 10.416 4.1665H39.5827C43.0345 4.1665 45.8327 6.96472 45.8327 10.4165V39.5832C45.8327 43.0349 43.0345 45.8332 39.5827 45.8332H10.416C6.96424 45.8332 4.16602 43.0349 4.16602 39.5832V10.4165Z" fill="black"/>
                                                </svg>
                                                <span className='checkbox-tick inline-block w-4 h-4 absolute left-1/2 -translate-x-1/2 scale-0 rotate-45'></span>
                                            </div>
                                            <label className='cursor-pointer' for="photonics">
                                                <p className='text-lg md:text-2xl inter-400 text-black pl-4 md:pl-6'>{value.course_name}</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}