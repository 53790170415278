import { API_URL } from "../utility/constants";

export async function getAllCohortData(token) {
    try{
        const response = await fetch(API_URL+"users/cohort/getAllCohorts", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAllCohortDataWithAllStatus(token) {
    try{
        const response = await fetch(API_URL+"users/cohort/getAllCohortsWithAllStatus", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addCohortData(data, token) {
    try{
        const response = await fetch(API_URL+"users/cohort/addCohort", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function updateCohortData(data, token) {
    try{
        const response = await fetch(API_URL+"users/cohort/updateCohort", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addCohortLearner(token, data) {
    try{
        const response = await fetch(API_URL+"users/cohort/addCohortLearner", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function updateCohortLearner(token, data) {
    try{
        const response = await fetch(API_URL+"users/cohort/updateCohortLearner", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCohortCoursesData(token, id) {
    try{
        const response = await fetch(API_URL+"users/cohort/getCohortCoursesById?cohort_id="+id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addCohortCourseData(token, data) {
    try{
        const response = await fetch(API_URL+"users/cohort/addCohortCourses", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function updateCohortCourseData(token, data) {
    try{
        const response = await fetch(API_URL+"users/cohort/updateCohortCourses", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};