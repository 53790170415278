import React, { useState, useEffect } from 'react';

const GameContent = ({ pedagogyValue }) => {
    const [fullView, setFullView] = useState(0);
    const resetFullView = () => {
        fullView === 0 ? setFullView(1) : setFullView(0);
    };


    return (
        <div>
            {pedagogyValue.pedagogy_type === 'GAME' ? (
                <div className='lg:pt-10 pt-8 lg:px-12 px-6 pb-8 border-b-2 border-[#D0D0D0]'>
                    <div className='video-box w-full h-[500px] lg:h-[760px] 2xl:h-[920px]'>
                        <iframe
                            title="Game"
                            src={pedagogyValue.url}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                        >
                        </iframe>
                    </div>
                    <h4 className='text-xl text-black inter-700 mt-4'>{pedagogyValue.title}</h4>
                    {pedagogyValue.text !== undefined && pedagogyValue.text !== '' && pedagogyValue.text !== null ? (
                        <>
                            {fullView === undefined || fullView === 0 ? (
                                <p className='text-base inter-400 text-black mt-4'>
                                    {pedagogyValue.text.substring(0, 300)} &nbsp;&nbsp;&nbsp;
                                    {pedagogyValue.text.length <= 300 ? (
                                        null
                                    ): (
                                        <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show More</a>
                                    )}                                
                                </p>
                            ) : (
                                <p className='text-base inter-400 text-black mt-4'>
                                    {pedagogyValue.text} &nbsp;&nbsp;&nbsp;
                                    <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show Less</a>
                                </p>
                            )}
                        </>
                    ) : (
                        null
                    )}
                </div>
            ) : (
                null
            )}
        </div>
    );
};

export default GameContent;