import moment from "moment";

export function getCurrentUTCTimestamp() {
    var utcMoment = moment.utc();    
    return utcMoment.format();
};

export function getNumberSuffix(number) {
    // Handle cases where number is less than 1 or not a finite number
    if (number <= 0 || !Number.isFinite(number)) {
      return '';
    }
  
    // Get the last digit of the number
    const lastDigit = number % 10;
    // Get the second last digit of the number
    const secondLastDigit = Math.floor(number / 10) % 10;
  
    // Determine the suffix based on the rules:
    // 1st, 2nd, 3rd, 4th, 5th... (special cases for 11th, 12th, 13th)
    if (secondLastDigit === 1 || lastDigit === 0 || lastDigit >= 4) {
      return 'th';
    } else if (lastDigit === 1) {
      return 'st';
    } else if (lastDigit === 2) {
      return 'nd';
    } else if (lastDigit === 3) {
      return 'rd';
    } else {
      return 'th';
    }
};