import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../services/UserService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { userDefaultPicture } from '../utility/constants';
import {formatSixDigitNumber} from '../services/CommonService';


export default function UserProfile() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [user, setUser] = useState({first_name: "", middle_name: "", last_name: "", email: "", password: "", status: "ACTIVE", role: "ACCOUNT_OWNER", state: "", city: "", country: "USA", addr_line1: "", addr_line2: "", zip_code: "", phone_no: ""});
    const [userViewType, setUserViewType] = useState("");
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            let userViewTypeString = sessionStorage.getItem('userViewType') !== null ? sessionStorage.getItem('userViewType') : "";
            if(userViewTypeString === ""){
                navigate("/admin/managelearner");
            }else{
                setUserViewType(userViewTypeString);
            }
            let adminUser = sessionStorage.getItem('userView') !== null ? JSON.parse(sessionStorage.getItem('userView')) : {};
            if(adminUser !== null && adminUser !== undefined && adminUser.email !== undefined){
                if(adminUser.image_link===undefined || adminUser.image_link === null){
                    adminUser.image_link = userDefaultPicture;
                }
                adminUser.uid = "UID" + formatSixDigitNumber(adminUser.id);
                adminUser.cohort_name_combined = adminUser.cohort_desc !== undefined && adminUser.cohort_desc !== null ? adminUser.cohort_desc + " - " : "";
                adminUser.cohort_name_combined = adminUser.cohort_name_combined + (adminUser.org_name !== undefined && adminUser.org_name !== null ? adminUser.org_name : "")
                setUserViewType(userViewTypeString);
                setUser(adminUser);
                sessionStorage.removeItem('userView');
                sessionStorage.removeItem('userViewType');
            }else{
                adminUser = {}
                navigate('/admin/managelearner')
            }
        }
    },[]);

    const onClickBack = () => {
        if(userViewType === "" || userViewType === "learner"){
            navigate("/admin/managelearner");
        }else{
            navigate("/admin/manageadmin");
        }
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
            <div className='lg:py-10 lg:px-24 xl:px-32 py-10 px-6'>
                <h3 className='text-2xl text-secondary text-center md:text-left inter-700 mb-6 md:mb-4 lg:mb-3'>Profile Details</h3>
                <div className='back-btn flex justify-start mb-5 md:mb-8'>
                        <a onClick={onClickBack} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                </div>
                <div className='flex flex-wrap justify-between w-full md:w-4/5 border rounded-3xl p-4 md:p-10 shadow-md'>
                    <div className='w-full'>
                        <div className="profile-icon-wrap relative mx-auto lg:ml-0 w-24 h-24 rounded-full mb-5 md:mb-8">
                            <div className="profile-icon-box w-24 h-24 rounded-full overflow-hidden">
                                <img id="profile-image" className='max-w-full max-h-full' src={user.image_link} alt='profile'/>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>Country</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.country}</p>
                    </div>
                    <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>First Name</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.first_name}</p>
                    </div>
                    <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>Middle Name</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.middle_name}</p>
                    </div>
                    <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>Last Name</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.last_name}</p>
                    </div>
                    {userViewType !== 'admin' ? (
                        <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                            <h3 className='text-base 2xl:text-2xl inter-400 text-black'>Organization</h3>
                            <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.cohort_name_combined}</p>
                        </div>
                    ): (
                        null
                    )}

                    <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>User ID</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.uid}</p>
                    </div>
                    {/* <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>Contact Person</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user}</p>
                    </div> */}
                    <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>Phone</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.phone_no}</p>
                    </div>
                    <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>Address 01</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.addr_line1}</p>
                    </div>
                    <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>Address 02</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.addr_line2}</p>
                    </div>
                    <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>State</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.state}</p>
                    </div>
                    <div className='w-full flex justify-between items-center py-2 md:py-4 border-b'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>City</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.city}</p>
                    </div>
                    <div className='w-full flex justify-between items-center py-2 md:py-4'>
                        <h3 className='text-base 2xl:text-2xl inter-400 text-black'>Zip Code</h3>
                        <p className='text-secondary text-lg 2xl:text-3xl inter-500'>{user.zip_code}</p>
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}