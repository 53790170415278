import { API_URL } from "../utility/constants";

export async function getReportData(token) {
    try{
        const response = await fetch(API_URL+"courses/learnerreport/getReportV2", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getDetailedReportData(token) {
    try{
        const response = await fetch(API_URL+"courses/learnerreport/getLearningReport", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};