import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getAllModules, getPedagogy, deletePedagogy } from '../../services/CourseService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


export default function PreviewAssessment() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [selectedCourse, setSelectedCourse] = useState({});
    const [selectedModule, setSelectedModule] = useState({});
    const [assessments, setAssessments] = useState([]);

    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            let moduleValue = sessionStorage.getItem('selectedModule') === null ? null : JSON.parse(sessionStorage.getItem('selectedModule'));
            if(moduleValue === null){
                navigate('/superadmin/manageassessment');
                toast.warning("Please choose a course and module!");
            }else{
                setSelectedModule(moduleValue);
                // sessionStorage.removeItem('selectedModule');
            }
            let assessmentPreview = sessionStorage.getItem('assessmentPreview') === null ? null : JSON.parse(sessionStorage.getItem('assessmentPreview'));
            if(assessmentPreview === null){
                navigate('/superadmin/manageassessment');
                toast.warning("No assessment to show!");
            }else{
                setAssessments(assessmentPreview);
                sessionStorage.removeItem('assessmentPreview');
            }
        }
    },[]);

    const editQuestion = (ass) => {
        sessionStorage.setItem('assessmentEdit', JSON.stringify(ass));
        sessionStorage.setItem('moduleValue', JSON.stringify(selectedModule));
        navigate('/superadmin/addassessment')
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='py-10 lg:px-12 px-6'>
                    <div className=''>
                        <div className='back-btn flex mb-4 md:mb-6'>
                            <a onClick={() => navigate('/superadmin/manageassessment')} className='cursor-pointer w-auto md:auto mb-4 md:mb-0 bg-tertiary hover:bg-black flex items-center justify-center px-6 h-8 2xl:h-12 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                        </div>
                        <h3 className='text-2xl text-secondary inter-700 mb-3 lg:hidden'>Assessment Preview</h3>
                        <div className='course-heading-wrap mb-5'>
                            <h2 className='text-[#1D548D] text-2xl md:text-3xl inter-400 mb-3'>{selectedModule.course_name}</h2>
                            <h3 className='text-tertiary text-xl lg:text-2xl inter-400 mb-3'>Lesson {selectedModule.module_seq_no}</h3>
                            <h2 className='text-black text-lg inter-400 mb-3'>{selectedModule.module_name}</h2>
                        </div>
                        {/* <div className='border-t border-[#7E7C7C] py-6'>
                            <h2 className='text-[#366494] text-2xl inter-400'>Quiz Title</h2>
                        </div> */}
                        {assessments.map((ass, index) => (
                            <>
                            {ass.type === 'TRUE_FALSE' ? (
                                <div className='quiz-preview-sngl border-t border-[#7E7C7C] py-5 md:py-8'>
                                    <div className='flex items-center mb-4'>
                                        <p className='text-tertiary text-xl inter-400'>Q - {index+1}</p>
                                        <div className='ml-10'>
                                            <a onClick={() => editQuestion(ass)} className='w-auto md:auto bg-[#E76457] hover:bg-black flex items-center justify-center px-6 h-8 2xl:h-12 text-white inter-500 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Edit</a>
                                        </div>
                                    </div>
                                    <p className='text-black text-lg inter-400'>{ass.primary_text}</p>
                                    <div className='flex items-center flex-wrap mt-4 md:mt-8'>
                                        <div className='customer-radio-sngl w-full md:w-1/2 mb-3 md:mb-0'>
                                            <input type="radio" id="true" name="radio-group"/>
                                            <label for="true">{ass.option1}</label>
                                        </div>
                                        <div className='customer-radio-sngl w-full md:w-1/2'>
                                            <input type="radio" id="false" name="radio-group"/>
                                            <label for="false">{ass.option2}</label>
                                        </div>
                                    </div>
                                </div>
                            ) : (null)}
                            {ass.type === 'YES_NO' ? (
                                <div className='quiz-preview-sngl border-t border-[#7E7C7C] py-5 md:py-8'>
                                    <div className='flex items-center mb-4'>
                                        <p className='text-tertiary text-xl inter-400'>Q - {index+1}</p>
                                        <div className='ml-10'>
                                            <a onClick={() => editQuestion(ass)} className='w-auto md:auto bg-[#E76457] hover:bg-black flex items-center justify-center px-6 h-8 2xl:h-12 text-white inter-500 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Edit</a>
                                        </div>
                                    </div>
                                    <p className='text-black text-lg inter-400'>{ass.primary_text}</p>
                                    <div className='flex items-center flex-wrap mt-4 md:mt-8'>
                                        <div className='customer-radio-sngl w-full md:w-1/2 mb-3 md:mb-0'>
                                            <input type="radio" id="yes" name="radio-group"/>
                                            <label for="yes">{ass.option1}</label>
                                        </div>
                                        <div className='customer-radio-sngl w-full md:w-1/2'>
                                            <input type="radio" id="no" name="radio-group"/>
                                            <label for="no">{ass.option2}</label>
                                        </div>
                                    </div>
                                </div>
                            ) : (null)}
                            {ass.type === 'MCQ' ? (
                                <div className='quiz-preview-sngl border-t border-[#7E7C7C] py-5 md:py-8'>
                                    <div className='flex items-center mb-4'>
                                        <p className='text-tertiary text-xl inter-400'>Q - {index+1}</p>
                                        <div className='ml-10'>
                                            <a onClick={() => editQuestion(ass)} className='cursor-pointer w-auto md:auto bg-[#E76457] hover:bg-black flex items-center justify-center px-6 h-8 2xl:h-12 text-white inter-500 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Edit</a>
                                        </div>
                                    </div>
                                    <p className='text-black text-lg inter-400'>{ass.primary_text}</p>
                                    <div className='flex items-center flex-wrap mt-4 md:mt-8'>
                                        <div className='customer-radio-sngl w-full mb-3 md:mb-5'>
                                            <input type="radio" id="ques4one" name="radio-group"/>
                                            <label for="ques4one">A. {ass.option1}</label>
                                        </div>
                                        <div className='customer-radio-sngl w-full mb-3 md:mb-5'>
                                            <input type="radio" id="ques4two" name="radio-group"/>
                                            <label for="ques4two">B. {ass.option2}</label>
                                        </div>
                                        <div className='customer-radio-sngl w-full mb-3 md:mb-5'>
                                            <input type="radio" id="ques4three" name="radio-group"/>
                                            <label for="ques4three">C. {ass.option3}</label>
                                        </div>
                                        <div className='customer-radio-sngl w-full mb-3 md:mb-5'>
                                            <input type="radio" id="ques4four" name="radio-group"/>
                                            <label for="ques4four">D. {ass.option4}</label>
                                        </div>
                                    </div>
                                </div>
                            ) : (null)}

                                {/* <div className='quiz-preview-sngl border-t border-[#7E7C7C] py-5 md:py-8'>
                                    <div className='flex items-center mb-4'>
                                        <p className='text-tertiary text-xl inter-400'>Q - 003</p>
                                        <div className='ml-10'>
                                            <a onClick={() => editQuestion(ass)} className='w-auto md:auto bg-[#E76457] hover:bg-black flex items-center justify-center px-6 h-8 2xl:h-12 text-white inter-500 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Edit</a>
                                        </div>
                                    </div>
                                    <p className='text-black text-lg inter-400'>Which of the following best describes the relationship between Artificial Intelligence (AI) and Machine Learning (ML)?</p>
                                    <div className='mt-4 md:mt-8'>
                                        <div className='icon-radio-button flex items-start md:items-center relative text-black hover:text-tertiary mb-3 md:mb-5'>
                                            <input className='absolute opacity-0 cursor-pointer w-6 h-6 md:w-8 md:h-8 z-20' type='checkbox' name='checkbox' id='tickone' />
                                            <div className='radio-icon-box cursor-pointer relative z-10'>
                                                <svg className='mx-auto w-6 h-6 md:w-8 md:h-8 transition duration-300' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.416 8.33317C9.26542 8.33317 8.33268 9.26591 8.33268 10.4165V39.5832C8.33268 40.7338 9.26542 41.6665 10.416 41.6665H39.5827C40.7333 41.6665 41.666 40.7338 41.666 39.5832V10.4165C41.666 9.26591 40.7333 8.33317 39.5827 8.33317H10.416ZM4.16602 10.4165C4.16602 6.96472 6.96424 4.1665 10.416 4.1665H39.5827C43.0345 4.1665 45.8327 6.96472 45.8327 10.4165V39.5832C45.8327 43.0349 43.0345 45.8332 39.5827 45.8332H10.416C6.96424 45.8332 4.16602 43.0349 4.16602 39.5832V10.4165Z" fill="black"/>
                                                </svg>
                                                <span className='checkbox-tick inline-block w-4 h-4 absolute left-1/2 -translate-x-1/2 scale-0 rotate-45'></span>
                                            </div>
                                            <label for="tickone">
                                                <p className='text-lg md:text-lg inter-400 text-black pl-2 md:pl-4 lg:pl-6'>A. AI is a subset of ML, focusing on algorithms that learn from data.</p>
                                            </label>
                                        </div>
                                        <div className='icon-radio-button flex items-start md:items-center relative text-black hover:text-tertiary mb-3 md:mb-5'>
                                            <input className='absolute opacity-0 cursor-pointer w-6 h-6 md:w-8 md:h-8 z-20' type='checkbox' name='checkbox' id='ticktwo' />
                                            <div className='radio-icon-box cursor-pointer relative z-10'>
                                                <svg className='mx-auto w-6 h-6 md:w-8 md:h-8 transition duration-300' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.416 8.33317C9.26542 8.33317 8.33268 9.26591 8.33268 10.4165V39.5832C8.33268 40.7338 9.26542 41.6665 10.416 41.6665H39.5827C40.7333 41.6665 41.666 40.7338 41.666 39.5832V10.4165C41.666 9.26591 40.7333 8.33317 39.5827 8.33317H10.416ZM4.16602 10.4165C4.16602 6.96472 6.96424 4.1665 10.416 4.1665H39.5827C43.0345 4.1665 45.8327 6.96472 45.8327 10.4165V39.5832C45.8327 43.0349 43.0345 45.8332 39.5827 45.8332H10.416C6.96424 45.8332 4.16602 43.0349 4.16602 39.5832V10.4165Z" fill="black"/>
                                                </svg>
                                                <span className='checkbox-tick inline-block w-4 h-4 absolute left-1/2 -translate-x-1/2 scale-0 rotate-45'></span>
                                            </div>
                                            <label for="ticktwo">
                                                <p className='text-lg md:text-lg inter-400 text-black pl-2 md:pl-4 lg:pl-6'>B. ML is a subset of AI, focusing on algorithms that learn from data.</p>
                                            </label>
                                        </div>
                                        <div className='icon-radio-button flex items-start md:items-center relative text-black hover:text-tertiary mb-3 md:mb-5'>
                                            <input className='absolute opacity-0 cursor-pointer w-6 h-6 md:w-8 md:h-8 z-20' type='checkbox' name='checkbox' id='tickthree' />
                                            <div className='radio-icon-box cursor-pointer relative z-10'>
                                                <svg className='mx-auto w-6 h-6 md:w-8 md:h-8 transition duration-300' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.416 8.33317C9.26542 8.33317 8.33268 9.26591 8.33268 10.4165V39.5832C8.33268 40.7338 9.26542 41.6665 10.416 41.6665H39.5827C40.7333 41.6665 41.666 40.7338 41.666 39.5832V10.4165C41.666 9.26591 40.7333 8.33317 39.5827 8.33317H10.416ZM4.16602 10.4165C4.16602 6.96472 6.96424 4.1665 10.416 4.1665H39.5827C43.0345 4.1665 45.8327 6.96472 45.8327 10.4165V39.5832C45.8327 43.0349 43.0345 45.8332 39.5827 45.8332H10.416C6.96424 45.8332 4.16602 43.0349 4.16602 39.5832V10.4165Z" fill="black"/>
                                                </svg>
                                                <span className='checkbox-tick inline-block w-4 h-4 absolute left-1/2 -translate-x-1/2 scale-0 rotate-45'></span>
                                            </div>
                                            <label for="tickthree">
                                                <p className='text-lg md:text-lg inter-400 text-black pl-2 md:pl-4 lg:pl-6'>C. AI and ML are completely separate fields with no overlap.</p>
                                            </label>
                                        </div>
                                        <div className='icon-radio-button flex items-start md:items-center relative text-black hover:text-tertiary'>
                                            <input className='absolute opacity-0 cursor-pointer w-6 h-6 md:w-8 md:h-8 z-20' type='checkbox' name='checkbox' id='tickfour' />
                                            <div className='radio-icon-box cursor-pointer relative z-10'>
                                                <svg className='mx-auto w-6 h-6 md:w-8 md:h-8 transition duration-300' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.416 8.33317C9.26542 8.33317 8.33268 9.26591 8.33268 10.4165V39.5832C8.33268 40.7338 9.26542 41.6665 10.416 41.6665H39.5827C40.7333 41.6665 41.666 40.7338 41.666 39.5832V10.4165C41.666 9.26591 40.7333 8.33317 39.5827 8.33317H10.416ZM4.16602 10.4165C4.16602 6.96472 6.96424 4.1665 10.416 4.1665H39.5827C43.0345 4.1665 45.8327 6.96472 45.8327 10.4165V39.5832C45.8327 43.0349 43.0345 45.8332 39.5827 45.8332H10.416C6.96424 45.8332 4.16602 43.0349 4.16602 39.5832V10.4165Z" fill="black"/>
                                                </svg>
                                                <span className='checkbox-tick inline-block w-4 h-4 absolute left-1/2 -translate-x-1/2 scale-0 rotate-45'></span>
                                            </div>
                                            <label for="tickfour">
                                                <p className='text-lg md:text-lg inter-400 text-black pl-2 md:pl-4 lg:pl-6'>D. ML and AI are the same, with no distinction between them.</p>
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                        ))}
                        {/* <div className='flex justify-between flex-wrap mt-4 md:mt-10 w-full md:w-4/5 lg:w-1/2'>
                            <a href='publish-quiz' className='w-full md:w-auto mb-4 md:mb-0 bg-secondary hover:bg-black flex items-center justify-center px-6 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Publish Quiz</a>
                            <a href='save-draft' className='w-full md:w-auto mb-4 md:mb-0 bg-[#E76457] hover:bg-black flex items-center justify-center px-6 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Save as Draft</a>
                            <a href='add-questions' className='w-full md:w-full mb-4 md:mb-0 bg-tertiary hover:bg-black flex items-center justify-center px-6 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow md:mt-8'>Add More Questions</a>
                        </div> */}
                    </div>
                </div>
            )}
        </div>
    );
};