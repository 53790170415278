import React, { useState, useEffect} from 'react';
import '../spinner.css';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../services/UserService';
import { getUserProfile, updateProfile } from '../services/ProfileService';
import { API_URL } from '../utility/constants';
import { getCountryList } from '../services/CommonService';

export default function AdminProfile() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userProfile, setUserProfile] = useState({});
    const [file, setFile] = useState(null);
    const [imageSrc, setImageSrc] = useState("https://d2hskb47g5lnpk.cloudfront.net/user_profile_images/profile_image_default.avif");
    const [countriesList, setCountriesList] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [countryCode, setCountryCode] = useState({});

    const [userDtl, setUserDtl] = useState({cohort_desc: ""});

    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            setUserDtl(userDetails.user);
            getCountries();
        }
    },[]);

    const getCountries = async () => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            const response = await getCountryList(userDetails.token);
            const countryList = response.data;
            setCountriesList(countryList);
            let countryValueMap = {};
            let cntry = [];
            let cntryCode = {};
            for(let i=0; i<countryList.length; i++){
                if(countryValueMap[countryList[i].country] === undefined){
                    countryValueMap[countryList[i].country] = 1;
                    cntry.push({label: countryList[i].country, value: countryList[i].country});
                }
                cntryCode[countryList[i].country] = countryList[i].phone_cd
            }
            setCountries(cntry);
            setCountryCode(cntryCode);
            await getUserProfileData(countryList);
        }else{
            toast.info("Please login again");
            navigate('/login');
        }
    };

    const getUserProfileData = async (countryList) => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            setLoading(true);
            const userProfileData = await getUserProfile(userDetails.token);
            if(userProfileData.error == false){
                setUserProfile(userProfileData.data);
                const userDetails = getUserDetails();
                userDetails.user.first_name = userProfileData.data.first_name;
                userDetails.user.middle_name = userProfileData.data.middle_name;
                userDetails.user.last_name = userProfileData.data.last_name;
                userDetails.user.image_link = userProfileData.data.image_link;
                localStorage.setItem('userDetails', JSON.stringify(userDetails));
                if(userProfileData.data.image_link !==undefined && userProfileData.data.image_link !==null && userProfileData.data.image_link !==''){
                    setImageSrc(userProfileData.data.image_link);
                }
                await changeCountryValue("country", userProfileData.data.country, countryList);
                await changeCountryValue("state", userProfileData.data.state, countryList);

            }
            setLoading(false);
        }else{
            toast.info("Please login again");
            navigate('/login');
        }
    };

    const handleCountryValueChange = async (name, value) => {

        if(name === 'country'){
            setUserProfile({
                ...userProfile,
                ["state"]: undefined,
                [name]: value,
                ["city"]: undefined
            });  
        } else if(name === 'state'){
                setUserProfile({
                    ...userProfile,
                    [name]: value,
                    ["city"]: undefined
                });  
            
        }else{
            setUserProfile({
                ...userProfile,
                [name]: value
            });
        }
        await changeCountryValue(name, value, countriesList);
    };

    const changeCountryValue = async (name, value, countryList) => {
        if(name === 'country'){
            let valueMap = {};
            let values = [];
                      
            for(let i=0; i<countryList.length; i++){
                if(countryList[i].country === value && valueMap[countryList[i].state] === undefined){
                    valueMap[countryList[i].state] = 1;
                    values.push({label: countryList[i].state, value: countryList[i].state});
                }
            }
            setStates(values);
        }
        if(name === 'state'){
            let valueMap = {};
            let values = [];
                      
            for(let i=0; i<countryList.length; i++){
                if(countryList[i].state === value && valueMap[countryList[i].city] === undefined){
                    valueMap[countryList[i].city] = 1;
                    values.push({label: countryList[i].city, value: countryList[i].city});
                }
            }
            setCities(values);
        }
    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setUserProfile({
          ...userProfile,
          [name]: value,
        });
    };

    const validateUserProfile = () => {
        return userProfile.first_name !==undefined
        && userProfile.first_name !== null
        && userProfile.first_name !== ''
        && userProfile.last_name !==undefined
        && userProfile.last_name !== null
        && userProfile.last_name !== ''
        && userProfile.country !==undefined
        && userProfile.country !== null
        && userProfile.country !== ''
        && userProfile.addr_line1 !==undefined
        && userProfile.addr_line1 !== null
        && userProfile.addr_line1 !== ''
        && userProfile.zip_code !==undefined
        && userProfile.zip_code !== null
        && userProfile.zip_code !== ''
        && userProfile.city !==undefined
        && userProfile.city !== null
        && userProfile.city !== ''
        && userProfile.state !==undefined
        && userProfile.state !== null
        && userProfile.state !== '';
    };

    const handleProfileChange = async () => {
        const userDetails = getUserDetails();
        const isValid = validateUserProfile(userProfile);
        if(userProfile.phone_no && userProfile.phone_no.length > 10){
            toast.warn("Phone number length is greater than 10 digits!");
        }
        else if(userProfile.zip_code && userProfile.zip_code.length > 5){
            toast.warn("Zip Code length is greater than 5 digits!");
        }
        else if(isValid){
            if(userDetails.token){
                setLoading(true);
      
                let resp = await updateProfile(userProfile, userDetails.token);
                setLoading(false);
                if(resp.error===false){
                    await getUserProfileData(countriesList);
                    toast.success(resp.data);
                    window.location.reload();
                }else{
                    toast.error(resp.data);
                }
              }else{
                    toast.info("Please login again");
                    navigate('/login');        
              }
        }else{
            toast.error("Mandatory Fields are empty");
        }
        
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        const selectFile = event.target.files[0];
        if (selectFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImageSrc(reader.result);
            };
            reader.readAsDataURL(selectFile);
        }
    };

    const handleUpload = async () => {
        const userDetails = getUserDetails();
        if (!file) {
          toast.error('Please select a file.');
          return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            setLoading(true);
            const response = await fetch(API_URL+'users/profile/uploadProfileImage', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + userDetails.token
                },
                body: formData
            });
            setLoading(false);
        
            if (response.error) {
                toast.error('Failed to upload file');
            }else{
                const data = await response.json();
                await getUserProfileData(countriesList);
                toast.success(data.data);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error uploading file: ', error);
        }
    };

    const formatSixDigitNumber = (num) => {
        if(num === undefined){
            return "";
        }
        let numStr = num.toString();
        while (numStr.length < 6) {
          numStr = '0' + numStr;
        }
        return numStr;
    };

    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
            ) : (
                <div className='lg:py-10 lg:px-32 py-10 px-10'>
                    <h3 className='text-2xl text-secondary text-center inter-700 mb-6 md:mb-4 lg:mb-3 lg:hidden'>My Profile</h3>
                    <form>
                        <div className="profile-icon-wrap relative mx-auto lg:ml-0 w-32 h-32 rounded-full mb-5 md:mb-8">
                            <div className="profile-icon-box w-32 h-32 rounded-full overflow-hidden">
                                <img id="profile-image" className='max-w-full max-h-full' src={imageSrc} alt='profile'/>
                            </div>
                            <label htmlFor="prfl-file-upload">
                                <img className='w-6 h-6' src='../images/pencil-icon.svg' alt='pencil'/>
                            </label>
                            <input type="file" id="prfl-file-upload" accept="image/*" onChange={handleFileChange} />
                        </div>
                        <div className='changpass-btn flex justify-center lg:justify-start mb-5 md:mb-8'>
                        <a onClick={() => handleUpload()} className='cursor-pointer bg-[#58C181] hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Update Profile Picture</a>
                        </div>
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Country <span className='text-[#f00]'>*</span></label>
                                <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                    options={countries}
                                    value={countries.find(option => option.value === userProfile.country)}
                                    onChange={(values) => handleCountryValueChange("country", values.value)}
                                />
                            </div>
                        </div>
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-1/4 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>First Name <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="first_name" value={userProfile.first_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full md:w-1/4 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Middle Name </label>
                                <input type='text' name="middle_name" value={userProfile.middle_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full md:w-1/4 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Last Name <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="last_name" value={userProfile.last_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                        </div>
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-1/4 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Organization</label>
                                <input type='text' name="customer_name" value={userProfile.customer_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-5 border-[#B44D71] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required disabled/>
                            </div>
                            <div className='form-field w-full md:w-1/4 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>User ID </label>
                                <input type='text' name="user_id" value={"UID"+formatSixDigitNumber(userProfile.user_id)} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-5 border-[#B44D71] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required disabled/>
                            </div>
                            {/* <div className='form-field w-full md:w-1/4 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Cohort </label>
                                <input type='text' name="cohort_desc" value={userDtl.cohort_desc} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-5 border-[#B44D71] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required disabled/>
                            </div> */}
                        </div>
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Phone </label>
                                <div className='relative'>
                                    <input type='number' name="phone_no" value={userProfile.phone_no} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 pr-6 pl-16  2xl:pr-10 transition duration-300' required/>
                                    <div className='absolute left-0 top-0 w-14'>
                                        <input type='text' name="country_code" value={countryCode[userProfile.country]} onChange={handleValueChange} placeholder='' className='inter-400 bg-transparent text-black text-base border-r border-[#bbb] focus:border-[#B44D71] text-center h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-2 transition duration-300 inline-block' required disabled/>
                                    </div>
                                </div>  
                            </div>
                            <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Address 01 <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="addr_line1" value={userProfile.addr_line1} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                        </div>
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Address 02 </label>
                                <input type='text' name="addr_line2" value={userProfile.addr_line2} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>State <span className='text-[#f00]'>*</span></label>
                                <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                    options={states}
                                    value={states.find(option => option.value === userProfile.state)}
                                    onChange={(values) => handleCountryValueChange("state", values.value)}
                                />
                            </div>
                        </div>
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>City <span className='text-[#f00]'>*</span></label>
                                <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                    options={cities}
                                    value={cities.find(option => option.value === userProfile.city)}
                                    onChange={(values) => handleCountryValueChange("city", values.value)}
                                />
                            </div>
                            <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Zip Code <span className='text-[#f00]'>*</span></label>
                                <input type='number' name="zip_code" value={userProfile.zip_code} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                        </div>
                        <div className='flex justify-between flex-wrap'>
                            <div className='w-full md:w-2/5 mb-5 md:mb-8'>
                                <button type="button" onClick={() => handleProfileChange()} className='w-full md:w-auto  bg-primary hover:bg-secondary flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Update Profile</button>
                            </div>
                            <div className='w-full md:w-2/5 mb-5 md:mb-8'>
                                <button type="button" onClick={() => navigate('/admin/adminpasswordreset')} className='w-full md:w-auto  bg-primary hover:bg-secondary flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Change Password</button>
                            </div>
                        </div>
                    </form>
                </div>
            )
          }
        </div>
    );
}

// Please use this javascript for file upload ++++++++++++++++++++++++++++++++++++++++++++++++++
    // const fileUpload = (event) => {
    // const files = event.target.files;
    // const filesLength = files.length;
    // if (filesLength > 0) {
    //     const imageSrc = URL.createObjectURL(files[0]);
    //     const imagePreviewElement = document.querySelector("#profile-image");
    //     imagePreviewElement.src = imageSrc;
    //     imagePreviewElement.style.display = "block";
    // }
    // };