import React, { useState, useEffect} from 'react';
import '../../spinner.css';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getSuperAdminDetailedReport, getSuperAdminAllCohortEvaluationReport, getSuperAdminAllCohorts, getSuperAdminCohortEvaluationReport } from '../../services/SuperAdminReportService';
import { fetchCoordinates } from '../../services/CommonService';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { MAPS_API_KEY } from '../../utility/constants';

export default function SuperAdminCohortReport() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [evalReport, setEvalReport] = useState({"avg_attendance": 0, "avg_po_score": 0});
    const [cohortReport, setCohortReport] = useState({active_student: 0, avg_score: 0, total_student: 0, user_less_than_35: 0});
    const [allCohortReport, setAllCohortReport] = useState({total_student: 0, active_student: 0, no_of_student_show_improvement: 0, no_of_student_completed_atleast_one_course: 0});
    const [cohorts, setCohorts] = useState([]);
    const [selectedCohort, setSelectedCohort] = useState({});
    const [locations, setLocations] = useState([]);
    const mapStyles = { height: "50vh", width: "100%" };

    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            getAllData();
        }
    },[]);

    const getAllData = async () => {
        const userDetails = getUserDetails();
        setLoading(true);
        await getSuperAdminAllCohortsData(userDetails.token);
        await getSuperAdminDetailedReportData();
        await getSuperAdminAllCohortEvaluationReportData();
        setLoading(false);
    };

    const getSuperAdminAllCohortEvaluationReportData = async () => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            const response = await getSuperAdminAllCohortEvaluationReport(userDetails.token);
            if(!response.error){
                setAllCohortReport(response.data);
            }
        }
    };

    const getSuperAdminCohortEvaluationReportData = async (cohort_id, customer_id) => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            const response = await getSuperAdminCohortEvaluationReport(userDetails.token, cohort_id, customer_id);
            if(!response.error){
                if(response.data.total_student === "0"){
                    response.data["active_student"] = 0;
                    response.data["avg_score"] = 0;
                    response.data["user_less_than_35"] = 0; 
                }
                setCohortReport(response.data);
            }
        }else{

        }
    };

    const getSuperAdminDetailedReportData = async () => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            const response = await getSuperAdminDetailedReport(userDetails.token);
            if(!response.error){
                setEvalReport(response.data);
            }
        }else{

        }
    };

    const getSuperAdminAllCohortsData = async (token) => {
        const cohortsData = await getSuperAdminAllCohorts(token);
        if(cohortsData.error){
            return [];
        }else{
            let cohortList = cohortsData.data;
            let addresses = [];
            for(let i=0; i<cohortList.length;i++){
                cohortList[i]['value'] = cohortList[i]['id'];
                cohortList[i]['label'] = cohortList[i]['cohort_desc'] + (cohortList[i]['org_name'] !== null && cohortList[i]['org_name'] !== undefined ? " - " + cohortList[i]['org_name'] : "");
                let ch = cohortList[i];
                let addr = "";
                if(ch.org_name !== undefined && ch.org_name !== null){
                    addr += ch.org_name;
                }
                addr += " ";
                if(ch.address !== undefined && ch.address !== null){
                    addr += ch.address;
                }
                addr += " ";
                if(ch.city !== undefined && ch.city !== null){
                    addr += ch.city;
                }
                addr += " ";
                if(ch.state !== undefined && ch.state !== null){
                    addr += ch.state;
                }
                addr += " ";
                if(ch.zip_code !== undefined && ch.zip_code !== null){
                    addr += ch.zip_code;
                }

                addresses.push({addr: addr, cohort_id: ch.id, label: cohortList[i]['label']});
            }

            let lc = await fetchCoordinates(addresses);
            setLocations(lc);
            setCohorts(cohortsData.data);
        }
    };

    const handleCohortSelectChange = async (cohortValue) => {
        let cohortData = {};
        for(let i =0; i<cohorts.length; i++){
            if(cohorts[i].id === cohortValue){
                cohortData = cohorts[i];
                setSelectedCohort(cohorts[i]);
            }
        }
        getSuperAdminCohortEvaluationReportData(cohortValue, cohortData.customer_id);
    };

    const learnerPerformanceReport = () => {
        sessionStorage.setItem('learnerReportType', 'all');
        sessionStorage.setItem('selectedCohortForReport', JSON.stringify(selectedCohort))
        navigate('/superadmin/superadminlearnerreport');
    };


    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
            ) : (
                <div className='pt-10 lg:px-12 px-6 pb-12'>
                    <div className='back-btn flex justify-start mb-6 md:mb-6'>
                        <a onClick={() => navigate('/superadmin/superadminreport')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div>
                    <h3 className='text-2xl text-secondary inter-700 lg:hidden mb-4'>Cohort Report</h3>
                    <div className='cohort-big-card rounded-3xl lg:p-10 p-8 mb-8'>
                        <h2 className='text-3xl text-white inter-700 mb-4'>Summary</h2>
                        <div className='flex items-center flex-wrap'>
                            <div className='w-full md:w-1/2'>
                                <div className='cohort-big-card-left'>
                                    <div className='mb-3 md:mb-6'>
                                        <h3 className='text-2xl text-white inter-700'>{Math.ceil(evalReport.avg_attendance)}%</h3>
                                        <p className='text-base 2xl:text-lg text-white inter-400'>Avg. Course Attendance</p>
                                    </div>
                                    <div className=''>
                                        <h3 className='text-2xl text-white inter-700'>{Math.ceil(evalReport.avg_po_score)}%</h3>
                                        <p className='text-base 2xl:text-lg text-white inter-400'>Avg Post-Learning Assessment Score</p>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full md:w-1/2 mt-3 md:mt-0'>
                                <div className='cohort-big-card-right'>
                                    <p className='text-xl text-white inter-400 mb-3'><span className='inter-700'>{allCohortReport.total_student}</span> Students </p>
                                    <p className='text-xl text-white inter-400 mb-3'><span className='inter-700'>{allCohortReport.active_student}</span> Active </p>
                                    <p className='text-xl text-white inter-400 mb-3'><span className='inter-700'>{allCohortReport.no_of_student_completed_atleast_one_course}</span> Completed at least 1 course </p>
                                    <p className='text-xl text-white inter-400 mb-0'><span className='inter-700'>{allCohortReport.no_of_student_show_improvement}</span> Shows Improvement in Score </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='hidden'>
                        <div className='flex justify-center flex-wrap mb-8'>
                                <div className='w-full md:w-3/5'>
                                    <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                        options={cohorts}
                                        value={cohorts.find(option => option.value === selectedCohort.id)}
                                        onChange={(values) => handleCohortSelectChange(values.value)}
                                    />
                                </div>
                        </div>
                        {selectedCohort.id !== undefined ? 
                            (
                                <div className='cohort-grade-card-wrap flex flex-wrap justify-center'>
                                    <div className='cohort-grade-card-sngl text-center rounded-3xl lg:p-10 p-8 w-full md:w-2/5'>
                                        <h3 className='text-3xl text-white inter-700 mb-7'>{selectedCohort.label}</h3>
                                        <div className='mb-6'>
                                            <h4 className='text-2xl text-white inter-700'>{cohortReport.total_student}</h4>
                                            <p className='text-base 2xl:text-lg text-white inter-400 leading-none'>Students</p>
                                        </div>
                                        <div className='mb-6'>
                                            <h4 className='text-2xl text-white inter-700'>{cohortReport.active_student}</h4>
                                            <p className='text-base 2xl:text-lg text-white inter-400 leading-none'>Active on Platform</p>
                                        </div>
                                        <div className='mb-6'>
                                            <h4 className='text-2xl text-white inter-700'>{Math.ceil(cohortReport.avg_score)}%</h4>
                                            <p className='text-base 2xl:text-lg text-white inter-400 leading-none'>Avg Assessment Score</p>
                                        </div>
                                        <div className='mb-0'>
                                            <h4 className='text-2xl text-white inter-700'>{cohortReport.user_less_than_35} Students</h4>
                                            <p className='text-base 2xl:text-lg text-white inter-400 leading-none'>With less than 35% Score</p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }
                    </div>
                    <div className='cohort-map flex justify-center'>
                        {locations.length > 0 ? (
                            <LoadScript googleMapsApiKey={MAPS_API_KEY}>
                                <GoogleMap mapContainerStyle={mapStyles} zoom={10} center={locations.length > 0 ? locations[0].position : { lat: 37.7749, lng: -122.4194 }}>
                                    {locations.map((place, index) => (
                                        <Marker onClick={() => handleCohortSelectChange(place.cohort_id)} key={index} position={place.position} label={place.label} />
                                    ))}
                                </GoogleMap>
                            </LoadScript>

                        ) : (
                            null
                        )}

                    </div>
                    {selectedCohort.id !== undefined ? 
                            (
                                <>
                                <div className='cohort-grade-card-wrap flex flex-wrap justify-center mt-4'>
                                    <div className='cohort-grade-card-sngl text-center rounded-3xl lg:p-10 p-8 w-full md:w-2/5'>
                                        <h3 className='text-3xl text-white inter-700 mb-7'>{selectedCohort.label}</h3>
                                        <div className='mb-6'>
                                            <h4 className='text-2xl text-white inter-700'>{cohortReport.total_student}</h4>
                                            <p className='text-base 2xl:text-lg text-white inter-400 leading-none'>Students</p>
                                        </div>
                                        <div className='mb-6'>
                                            <h4 className='text-2xl text-white inter-700'>{cohortReport.active_student}</h4>
                                            <p className='text-base 2xl:text-lg text-white inter-400 leading-none'>Active on Platform</p>
                                        </div>
                                        <div className='mb-6'>
                                            <h4 className='text-2xl text-white inter-700'>{Math.ceil(cohortReport.avg_score)}%</h4>
                                            <p className='text-base 2xl:text-lg text-white inter-400 leading-none'>Avg Assessment Score</p>
                                        </div>
                                        <div className='mb-0'>
                                            <h4 className='text-2xl text-white inter-700'>{cohortReport.user_less_than_35} Students</h4>
                                            <p className='text-base 2xl:text-lg text-white inter-400 leading-none'>With less than 35% Score</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-center flex-wrap pt-4 mb-8'>
                                    <div className='w-full md:w-3/5'>
                                        <a onClick={learnerPerformanceReport} className='cursor-pointer course-btn-yellow bg-[#E76457] hover:bg-black flex items-center justify-center h-10 2xl:h-12 text-white inter-600 text-base md:text-sm lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'><span className='text-xl'>+ </span> View in Detail</a>
                                    </div>
                                </div>
                                </>
                        ) : (
                            null
                        )
                    }
                    
                </div>
            )
          }
        </div>
    );
}