import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { toast } from 'react-toastify';
import { getAllSubscriptions, addSubscription, updateSubscription } from '../../services/SubscriptionService';

export default function AddSubscription() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [edit, setEdit] = useState(false);
    const [subscription, setSubscription] = useState({subscription_status: "ACTIVE"});
    useEffect(() => {
        const userDetail = getUserDetails();
        if(userDetail.token === undefined){
          navigate('/login');
        }else{
            setUserDetails(userDetail);
            let subscriptionEdit = sessionStorage.getItem('subscriptionEdit') === null ? null : JSON.parse(sessionStorage.getItem('subscriptionEdit'));
            if(subscriptionEdit !== null){
                setSubscription(subscriptionEdit);
                setEdit(true);
                sessionStorage.removeItem('subscriptionEdit');
            }
        }
    },[]);

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setSubscription({
          ...subscription,
          [name]: value,
        });
    };

    const validateSubscription = () => {
        if(subscription.subscription_code === undefined || subscription.subscription_code === null || subscription.subscription_code === ''
            || subscription.subscription_desc === undefined || subscription.subscription_desc === null || subscription.subscription_desc === ''
            || subscription.monthly_price_per_user === undefined || subscription.monthly_price_per_user === null || subscription.monthly_price_per_user === ''
            || subscription.yearly_price_per_user === undefined || subscription.yearly_price_per_user === null || subscription.yearly_price_per_user === '' 
        ){
           return false; 
        }
        return true;
    };

    const addSubscriptionData = async () => {
        if(validateSubscription()){
            setLoading(true);
            let resp = await addSubscription(userDetails.token, subscription);
            if(resp.error === false){
                toast.success("Subscription added successfully!");
            }else{
                toast.error("Some error occurred while adding Subscription!");
            }
            setLoading(false);
        }else{
            toast.warn("All fields are mandatory! Please fill all fields.")
        }
    };

    const updateSubscriptionData = async () => {
        if(validateSubscription()){
            setLoading(true);
            let resp = await updateSubscription(userDetails.token, subscription);
            if(resp.error == false){
                toast.success("Subscription updated successfully!");
            }else{
                toast.error("Some error occurred while adding Subscription!");
            }
            setLoading(false);
        }else{
            toast.warn("All fields are mandatory! Please fill all fields.")
        }

    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='lg:py-10 py-6'>
                    <h3 className='text-2xl text-secondary inter-700 px-6 mb-6 md:mb-4 lg:mb-3 lg:hidden'>{edit? "Update Subscription" :"Add Subscription"}</h3>
                    <div className='back-btn flex justify-start lg:px-24 xl:px-32 px-6 mb-5 md:mb-8'>
                        <a onClick={() => navigate('/superadmin/managesubscription')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div>
                    <form className='w-full'>
                        <div className='lg:px-24 xl:px-32 px-6 mb-6 md:pb-6'>
                            <div className='flex justify-between flex-wrap'>
                                <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Subscription Code</label>
                                    <input type='text' name="subscription_code" value={subscription.subscription_code} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                </div>
                            </div>
                            <div className='flex justify-between flex-wrap'>
                                <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Subscription Name</label>
                                    <input type='text' name="subscription_desc" value={subscription.subscription_desc} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                </div>
                            </div>

                            <div className='flex justify-between flex-wrap'>
                                <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Price Per User Per Month (USD)</label>
                                    <input type='number' name="monthly_price_per_user" value={subscription.monthly_price_per_user} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                </div>
                            </div>
                            <div className='flex justify-between flex-wrap'>
                                <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Price Per User Per Year (USD)</label>
                                    <input type='number' name="yearly_price_per_user" value={subscription.yearly_price_per_user} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                </div>
                            </div>
                            <div className='flex justify-between flex-wrap'>
                                <div className='form-field w-full md:w-2/5 mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Tax %</label>
                                    <input type='number' name="tax_percentage" value={subscription.tax_percentage} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                </div>
                            </div>
                            
                        </div>
                        <div className='flex items-center lg:px-24 xl:px-32 px-6 mt-2 2xl:mt-4'>
                            {edit ? (
                                <button type="button" onClick={() => updateSubscriptionData()} className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Update Subscription</button>
                            ) : (
                                <button type="button" onClick={() => addSubscriptionData()} className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>+ Add New Subscription</button>
                            )}
                        </div>

                    </form>
                </div>
            )}
        </div>
    );
};