import React, {createContext, useContext, useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch, useNavigate, Routes, Navigate } from 'react-router-dom';
import '../spinner.css';
import { getCustomerAdminPermission, getUserDetails, logout } from '../services/UserService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminHeader from './layout/AdminHeader';
import AdminSidebar from './layout/AdminSidebar';
import AdminDashboard from './AdminDashboard';
import ManageAdmin from './admin/ManageAdmin';
import CreateAdmin from './admin/CreateAdmin';
import ManageCohort from './cohort/ManageCohort';
import CreateCohort from './cohort/CreateCohort';
import ManageLearner from './learner/ManageLearner';
import CreateLearner from './learner/CreateLearner';
import AdminProfile from './AdminProfile';
import Evaluation from './evaluation/Evaluation';
import CohortReport from './evaluation/CohortReport';
import LearnerReport from './evaluation/LearnerReport';
import LearningReport from './evaluation/LearningReport';
import InviteNewLearner from './learner/InviteNewLearner';
import CohortCourses from './cohort/CohortCourses';
import CustomizePortal from './CustomizePortal';
import NoAccess from './NoAccess';
import AdminPermission from './admin/AdminPermission';
import UserReport from './evaluation/UserReport';
import UserProfile from './UserProfile';
import AdminPasswordReset from './AdminPasswordReset';
import { permissionsStore } from '../services/Stores';
import Ngss from './Ngss';

const useAuthorization = (permission, permissions) => {
  //const permissions = {"P1" : false, "P2": true, "P3": true, "P4": true, "P5": true}
  const navigate = useNavigate();

  if (permission !== "P0" && !permissions[permission]) {
    navigate('/admin/noaccess', { replace: true });
    return false;
  }

  return true;
};

const ProtectedRoute = ({ element: Element, permission, permissions }) => {
  const navigate = useNavigate();
  const isAuthorized = useAuthorization(permission, permissions);
  if(isAuthorized === false){
    return null;
  }else{
    return <Element /> 
  }

};

export default function AdminRoutes() {
  const navigate = useNavigate();
  const { permissions, setPermissions, clearPermissions } = permissionsStore();
  useEffect(() => {
      const userDetails = getUserDetails();
      if(userDetails.token === undefined){
        navigate('/login');
      } else if(userDetails.user.role === "LEARNER"){
        navigate('/learner/dashboard');
      } else if(userDetails.user.role === "SUPER_ADMIN"){
        navigate('/superadmin/dashboard');
      } else{
        getUserPermissions(userDetails.token, userDetails.user.id);
      }
  },[]);
  const getUserPermissions = async (token, id) => {
      let resp = await getCustomerAdminPermission(token, id);
      if(resp.error || resp.data.length === 0){
          setPermissions({"P1": true, "P2": true, "P3": true, "P4": true, "P5": true});
          //sessionStorage.setItem("permissions", JSON.stringify({"P1": true, "P2": true, "P3": true, "P4": true, "P5": true}));
      }else{
        let permsObj = {};
        for( let i=0;i<resp.data.length; i++){

          permsObj[resp.data[i].permission_code] = true;
        }
        //sessionStorage.setItem("permissions", JSON.stringify(permsObj));
        setPermissions(permsObj);

      }
  };
  return (
    <div className='flex'>
      <div className='sidebar-wrap fixed top-0 left-0 h-full hidden lg:block z-50'>
        <AdminSidebar />
      </div>
      <div className='dash-rigth'>
        <div className='header-wrap'>
          <AdminHeader />
        </div>

         <Routes>
          <Route path="*" element={<AdminDashboard/>} permission="P0" permissions={permissions} />
          <Route path="/dashboard" element={<AdminDashboard/>} permission="P0" permissions={permissions} />
          
          <Route path="/manageadmin" element={<ProtectedRoute element={ManageAdmin} permission="P1" permissions={permissions} />} />
          <Route path="/createadmin" element={<ProtectedRoute element={CreateAdmin} permission="P1" permissions={permissions} />} />
          <Route path="/permission" element={<ProtectedRoute element={AdminPermission} permission="P1" permissions={permissions} />} />

          <Route path="/managecohort" element={<ProtectedRoute element={ManageCohort} permission="P2" permissions={permissions} />} />
          <Route path="/createcohort" element={<ProtectedRoute element={CreateCohort} permission="P2" permissions={permissions} />} />
          <Route path="/cohortcourses" element={<ProtectedRoute element={CohortCourses} permission="P2" permissions={permissions} />} />

          <Route path="/managelearner" element={<ProtectedRoute element={ManageLearner} permission="P3" permissions={permissions} />} />
          <Route path="/createlearner" element={<ProtectedRoute element={CreateLearner} permission="P3" permissions={permissions} />} />
          <Route path="/userprofile" element={<ProtectedRoute element={UserProfile} permission="P3" permissions={permissions} />} />
          <Route path="/invitelearner" element={<ProtectedRoute element={InviteNewLearner} permission="P3" permissions={permissions} />} />

          <Route path="/evaluation" element={<ProtectedRoute element={Evaluation} permission="P4" permissions={permissions} />} />
          <Route path="/learnerreport" element={<ProtectedRoute element={LearnerReport} permission="P4" permissions={permissions} />} />
          <Route path="/cohortreport" element={<ProtectedRoute element={CohortReport} permission="P4" permissions={permissions} />} />
          <Route path="/learningreport" element={<ProtectedRoute element={LearningReport} permission="P4" permissions={permissions} />} />
          <Route path="/userreport" element={<ProtectedRoute element={UserReport} permission="P4" permissions={permissions} />} />

          <Route path="/customizeportal" element={<ProtectedRoute element={CustomizePortal} permission="P5" permissions={permissions} />} />
          <Route path="/ngss" element={<Ngss/>} permission="P0" permissions={permissions} />


          <Route path="/noaccess" element={<NoAccess/>} permission="P0" permissions={permissions} />
          <Route path="/adminpasswordreset" element={<AdminPasswordReset/>} permission="P0" permissions={permissions} />
          <Route path="/profile" element={<AdminProfile/>} permission="P0" permissions={permissions} />

         </Routes>

      </div>
    </div>
  );
};
