import React, { useState, useEffect} from 'react';
import '../../spinner.css';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getAssignedCoursesData, getUserCoursesAndModulesAndLearningsData } from '../../services/LearningService';

export default function LearnerCourses() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [learnings, setLearnings] = useState({recentlyCompleted: {}, ongoing: {}, upcoming: {}, courses: []});
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            setUserDetails(userDetails);
            getAssignedCourses();
        }
    },[]);
    const getAssignedCourses = async () => {
        const userDetails = getUserDetails();
        setLoading(true);
        const assignedCourses = await getAssignedCoursesData(userDetails);
        if(assignedCourses.error){
          toast.error(assignedCourses.data);
        }else{
          let course_ids = [];
          for(let i=0; i < assignedCourses.data.length; i++){
            course_ids.push(assignedCourses.data[i].course_id);
          }
          getUserCoursesAndModulesAndLearnings(course_ids);
          
        }
        setLoading(false);
      };
      const getUserCoursesAndModulesAndLearnings = async (course_ids) => {
        const userDetails = getUserDetails();
        const assignedCourses = await getUserCoursesAndModulesAndLearningsData(userDetails.token, course_ids);
        if(assignedCourses.error){
          toast.error(assignedCourses.data);
          setLearnings({recentlyCompleted: {}, ongoing: {}, upcoming: {}, courses: []});
        }else{
          let data = assignedCourses.data;
          data.courses = groupModuleByCourse(assignedCourses.data.courses, course_ids);
          setLearnings(data);
        }
    };
    const openLearningPage = async (course, module) => {
        sessionStorage.setItem('modules', JSON.stringify(learnings));
        let currentModule = Object.assign(module);
        currentModule['course_id'] = course.course_id;
        currentModule['course_code'] = course.course_code;
        currentModule['course_name'] = course.course_name;
        currentModule['course_desc'] = course.course_desc;
        currentModule['color'] = course.color;
        if(module.module_type == 'ASSESSMENT'){
          sessionStorage.setItem("currentModule", JSON.stringify(module));
          navigate('/learner/assessment');
        }else if(module.module_type == 'THEORY'){
          sessionStorage.setItem("currentModule", JSON.stringify(module));
          navigate('/learner/theory');
        }
    };

    const groupModuleByCourse = (data, course_ids) => {
        let courseMap = {};
        for(let i = 0; i < data.length;i++){
          let courseAndModule = Object.assign(data[i]);
          if(courseMap[courseAndModule.course_id] === undefined){
            courseMap[courseAndModule.course_id] = {course_id: courseAndModule.course_id, course_name: courseAndModule.course_name, course_code: courseAndModule.course_code, course_desc: courseAndModule.course_desc, color: courseAndModule.color, modules: []}
          }
          courseMap[courseAndModule.course_id]["modules"].push({
            module_id: courseAndModule.module_id,
            pedagogy_count: courseAndModule.pedagogy_count,
            module_code: courseAndModule.module_code,
            module_name: courseAndModule.module_name,
            module_desc: courseAndModule.module_desc,
            module_type: courseAndModule.module_type,
            module_seq_no: courseAndModule.module_seq_no,
            learning_pedagogy_count: courseAndModule.learning_pedagogy_count === null || courseAndModule.learning_pedagogy_count === undefined ? 0 : courseAndModule.learning_pedagogy_count,
            status: courseAndModule.status,
          });
        }
        let courseList = Object.values(courseMap);
        const mappedData = course_ids.map(id => courseList.find(item => parseInt(item.course_id) === parseInt(id)));
        const sortedData = mappedData.filter(item => item);
        return sortedData;
    };

    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
          ) : (
            <div className='pt-10'>
                <h3 className='text-2xl text-secondary text-left inter-700 mb-3 md:mb-4 lg:mb-3 lg:hidden px-10'>My Technology</h3>
                {learnings.courses.map((value, index) => (
                    <div key={"key"+index} className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-center lg:px-12 px-0 lg:pb-12 ${index == 0? 'pb-8': 'pt-8 pb-8'} border-b-2 border-[#D0D0D0] gap-x-9 2xl:gap-x-20 gap-y-8 2xl:gap-y-16`}>
                        <div className='pr-5 md:text-center md:text-left px-10 md:px-0' data-aos="fade-up" data-aos-duration="1000">
                            <h3 className='text-3xl 2xl:text-4xl inter-700 text-black mb-4'>{value.course_name}</h3>
                            <p className='text-base md:text-lg lg:text-base 2xl:text-xl inter-400 text-black'>{value.course_desc}</p>
                        </div>
                        {value.modules.map((subValue, subIndex) => (
                            <div key={"subKey"+subIndex} className='hidden md:block h-full' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                                <a style={{ backgroundColor: `${value.color}` }} onClick={() => openLearningPage(value, subValue)} className={`cursor-pointer course-card h-full flex flex-col justify-between min-h-72 lg:min-h-60 2xl:min-h-[230px] rounded-3xl py-8 md:pl-7 pl-8 md:pr-10 pr-8 block hover:scale-105 hover:-translate-y-4 hover:shadow-lg transition-all duration-300 ease-in-out`}>
                                    <div>
                                        <div className='course-icon mb-4'>
                                        {subValue.status === 'STARTED' ? (
                                          <img className='lg:w-7 lg:h-7 h-9 2xl:w-8' src='../images/box-line.svg' alt='box line'/>
                                        ): (
                                          null
                                        )}
                                        {subValue.status === 'COMPLETED' || subValue.status === null ? (
                                          <img className='lg:w-7 lg:h-7 h-9 2xl:w-8' src='../images/greentick.svg' alt='greentick'/>
                                        ): (
                                          null
                                        )}
                                        {subValue.status === undefined || subValue.status === 'NOT_STARTED' ? (
                                          <img className='lg:w-7 lg:h-7 h-9 2xl:w-8' src='../images/book-w.svg' alt='book'/>
                                        ): (
                                          null
                                        )}
                                        </div>
                                        <div className='course-text mb-4'>
                                          <p className='lg:text-lg 2xl:text-xl lg:leading-6 text-white inter-600'>{subValue.module_name}</p>
                                          {subValue.module_type !== 'ASSESSMENT' ? (
                                              <>
                                              {subValue.pedagogy_count === null || subValue.pedagogy_count === undefined ? (
                                                <>
                                                  <p className='lg:text-base 2xl:text-lg text-[#F9EA98] inter-700 '>0 Pedagogies</p>
                                                  {/* <p className='lg:text-base 2xl:text-lg text-white inter-700 '>0% Completed</p> */}
                                                </>
                                              ) : (
                                                <>
                                                  <p className='lg:text-base 2xl:text-lg text-[#F9EA98] inter-700 '>{subValue.pedagogy_count} Pedagogies</p>
                                                  {Math.ceil(parseInt(subValue.learning_pedagogy_count)*100/parseInt(subValue.pedagogy_count)) > 0 ? (
                                                  <p className='lg:text-base 2xl:text-lg text-white inter-700 '>{Math.min(Math.ceil(parseInt(subValue.learning_pedagogy_count)*100/parseInt(subValue.pedagogy_count)), 100)}% Completed</p>
                                                  ): (
                                                    null
                                                  )}
                                                </>
                                              )}
                                              </>
                                            ) : (
                                              null
                                            ) 
                                          }
                                        {/* <p className='text-lg lg:text-base 2xl:text-lg text-[#F9EA98] inter-700 '>{subValue.module_seq_no} / {subValue.module_code}</p> */}
                                        </div>
                                    </div>
                                    <div className='course-btn-wrap'>
                                        {(subValue.status===undefined || subValue.status === 'NOT_STARTED') && subValue.module_type !== 'ASSESSMENT' ? (
                                            <div className='course-btn-yellow bg-[#F9EA98] hover:bg-white flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-black inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Start Learning</div>
                                        ): (
                                            null
                                        )}
                                        {(subValue.status===undefined || subValue.status === 'NOT_STARTED') && subValue.module_type === 'ASSESSMENT' ? (
                                            <div className='course-btn-yellow bg-[#F9EA98] hover:bg-white flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-black inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Take Test</div>
                                        ): (
                                            null
                                        )}
                                        {subValue.status==='STARTED' && subValue.module_type === 'ASSESSMENT' ? (
                                            <div className='course-btn-green bg-[#58C181] hover:bg-black flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-white inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Continue Test</div>

                                        ): (
                                            null
                                        )}
                                        {subValue.status==='STARTED' && subValue.module_type !== 'ASSESSMENT' ? (
                                            <div className='course-btn-green bg-[#58C181] hover:bg-black flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-white inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Continue Learning</div>

                                        ): (
                                            null
                                        )}
                                        {(subValue.status==='COMPLETED' || subValue.status===null) && subValue.module_type === 'ASSESSMENT' ? (
                                            <div className='course-btn-white bg-white hover:bg-[#F9EA98] flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-black inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Retake Test</div> 
                                        ): (
                                            null
                                        )}
                                        {subValue.status==='COMPLETED' && subValue.module_type !== 'ASSESSMENT' ? (
                                            <div className='course-btn-white bg-white hover:bg-[#F9EA98] flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-black inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Learn Again</div> 
                                        ): (
                                            null
                                        )}
                                    </div>
                                </a>  
                            </div>
                        ))}
                        <div className='md:hidden flex overflow-y-auto px-10 pb-2 pt-7 lg:pt-0 no-scrollbar'>
                        {value.modules.map((subValue, subIndex) => (
                            <div key={"subKey"+subIndex} className='courses-sngl-card w-64 mr-4' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                                <a style={{ backgroundColor: `${value.color}` }} onClick={() => openLearningPage(value, subValue)} className={`cursor-pointer course-card flex flex-col justify-between min-h-72 lg:min-h-60 2xl:min-h-[230px] rounded-3xl py-8 md:pl-7 pl-8 md:pr-10 pr-8 block hover:scale-105 hover:-translate-y-4 hover:shadow-lg transition-all duration-300 ease-in-out`}>
                                    <div>
                                        <div className='course-icon mb-4'>
                                        {subValue.status === 'STARTED' ? (
                                          <img className='lg:w-7 lg:h-7 h-9 2xl:w-8' src='../images/box-line.svg' alt='box line'/>
                                        ): (
                                          null
                                        )}
                                        {subValue.status === 'COMPLETED' || subValue.status === null ? (
                                          <img className='lg:w-7 lg:h-7 h-9 2xl:w-8' src='../images/greentick.svg' alt='greentick'/>
                                        ): (
                                          null
                                        )}
                                        {subValue.status === undefined || subValue.status === 'NOT_STARTED' ? (
                                          <img className='lg:w-7 lg:h-7 h-9 2xl:w-8' src='../images/book-w.svg' alt='book'/>
                                        ): (
                                          null
                                        )}
                                        </div>
                                        <div className='course-text mb-4'>
                                          <p className='lg:text-lg 2xl:text-xl lg:leading-6 text-white inter-600'>{subValue.module_name}</p>
                                          {subValue.module_type !== 'ASSESSMENT' ? (
                                              <>
                                              {subValue.pedagogy_count === null || subValue.pedagogy_count === undefined ? (
                                                <>
                                                  <p className='lg:text-base 2xl:text-lg text-[#F9EA98] inter-700 '>0 Pedagogies</p>
                                                  {/* <p className='lg:text-base 2xl:text-lg text-white inter-700 '>0% Completed</p> */}
                                                </>
                                              ) : (
                                                <>
                                                  <p className='lg:text-base 2xl:text-lg text-[#F9EA98] inter-700 '>{subValue.pedagogy_count} Pedagogies</p>
                                                  {Math.ceil(parseInt(subValue.learning_pedagogy_count)*100/parseInt(subValue.pedagogy_count)) > 0 ? (
                                                  <p className='lg:text-base 2xl:text-lg text-white inter-700 '>{Math.min(Math.ceil(parseInt(subValue.learning_pedagogy_count)*100/parseInt(subValue.pedagogy_count)), 100)}% Completed</p>
                                                  ): (
                                                    null
                                                  )}
                                                </>
                                              )}
                                              </>
                                            ) : (
                                              null
                                            ) 
                                          }
                                        {/* <p className='text-lg lg:text-base 2xl:text-lg text-[#F9EA98] inter-700 '>{subValue.module_seq_no} / {subValue.module_code}</p> */}
                                        </div>
                                    </div>
                                    <div className='course-btn-wrap'>
                                        {(subValue.status===undefined || subValue.status === 'NOT_STARTED') && subValue.module_type !== 'ASSESSMENT' ? (
                                            <div className='course-btn-yellow bg-[#F9EA98] hover:bg-white flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-black inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Start Learning</div>
                                        ): (
                                            null
                                        )}
                                        {(subValue.status===undefined || subValue.status === 'NOT_STARTED') && subValue.module_type === 'ASSESSMENT' ? (
                                            <div className='course-btn-yellow bg-[#F9EA98] hover:bg-white flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-black inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Take Test</div>
                                        ): (
                                            null
                                        )}
                                        {subValue.status==='STARTED' && subValue.module_type === 'ASSESSMENT' ? (
                                            <div className='course-btn-green bg-[#58C181] hover:bg-black flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-white inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Continue Test</div>

                                        ): (
                                            null
                                        )}
                                        {subValue.status==='STARTED' && subValue.module_type !== 'ASSESSMENT' ? (
                                            <div className='course-btn-green bg-[#58C181] hover:bg-black flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-white inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Continue Learning</div>

                                        ): (
                                            null
                                        )}
                                        {(subValue.status==='COMPLETED' || subValue.status===null) && subValue.module_type === 'ASSESSMENT' ? (
                                            <div className='course-btn-white bg-white hover:bg-[#F9EA98] flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-black inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Retake Test</div> 
                                        ): (
                                            null
                                        )}
                                        {subValue.status==='COMPLETED' && subValue.module_type !== 'ASSESSMENT' ? (
                                            <div className='course-btn-white bg-white hover:bg-[#F9EA98] flex items-center justify-center h-9 lg:h-8 2xl:h-10 text-black inter-600 text-lg lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Learn Again</div> 
                                        ): (
                                            null
                                        )}
                                    </div>
                                </a>  
                            </div>
                        ))}
                        </div>
                    </div>
                ))}
                
            </div>
          )}
          
        </div>
    );
}