import { API_URL } from "../utility/constants";


export async function getAllLearners(token, customer_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminLearners/getAllLearners?customer_id="+customer_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function deleteLearner(token, user_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminLearners/deleteLearner?user_id="+user_id, {
            method: 'DELETE',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};


export async function addCohortLearner(token, data) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminLearners/addCohortLearner", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function updateCohortLearner(token, data) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminLearners/updateCohortLearner", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addLearner(token, data) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminLearners/addLearner", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function updateLearner(token, data) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminLearners/updateLearner", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};
