import React, { useState, useEffect } from 'react';

const AudioContent = ({ pedagogyValue }) => {
    const [fullView, setFullView] = useState(0);
    const resetFullView = () => {
        fullView === 0 ? setFullView(1) : setFullView(0);
    };


    return (
        <div>
            {pedagogyValue.pedagogy_type === 'AUDIO' ? (
                <div className='lg:pt-10 pt-8 lg:px-12 px-6 pb-8 border-b-1 border-[#D0D0D0]'>
                    <div className='image-box mt-10'>
                        <img className='w-full' src={pedagogyValue.url} alt='' />
                        <h4 className='text-xl text-black inter-700 mt-4'>{pedagogyValue.title}</h4>
                        {pedagogyValue.text !== undefined && pedagogyValue.text !== '' && pedagogyValue.text !== null ? (
                            <>
                                {fullView === undefined || fullView === 0 ? (
                                    <p className='text-base inter-400 text-black mt-4'>
                                        {pedagogyValue.text.substring(0, 300)} &nbsp;&nbsp;&nbsp;
                                        {pedagogyValue.text.length <= 300 ? (
                                                null
                                        ): (
                                            <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show More</a>
                                        )}                                    
                                    </p>
                                ) : (
                                    <p className='text-base inter-400 text-black mt-4'>
                                        {pedagogyValue.text} &nbsp;&nbsp;&nbsp;
                                        <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show Less</a>
                                    </p>
                                )}
                            </>
                        ) : (
                            null
                        )}
                    </div>
                </div>
            ) : (
                null
            )}
        </div>
    );
};

export default AudioContent;