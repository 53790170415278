import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { toast } from 'react-toastify';
import { getCustomerReport } from '../../services/SuperAdminReportService';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { getRandomHexColor } from '../../services/CommonService';
// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default function SuperAdminCustomerReport() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [customerData, setCustomerData] = useState([]);
    const [customerReport, setCustomerReport] = useState({
        labels: [],
        datasets: [
          {
            label: 'Number Of Learner',
            data: [],
            backgroundColor: [],
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
    });
    
    // Chart options
    const options = {
        responsive: true,
        indexAxis: 'y',
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
                label: function(context) {
                    const index = context.dataIndex;
                    let customer_name = customerData[index].name;
                    let subscription_code = customerData[index].subscription_code;
                    let num_of_learners = customerData[index].num_of_learners === undefined ? 0 : customerData[index].num_of_learners;
                    let num_cohorts = customerData[index].num_cohorts === undefined ? 0 : customerData[index].num_cohorts;
                    let subscription_end_date = customerData[index].subscription_end_date;
                    let paid_amount = customerData[index].paid_amount === undefined ? 0 : customerData[index].paid_amount;
                    let total_amount = customerData[index].total_amount === undefined ? 0 : customerData[index].total_amount;

                    return [
                            "Name: "+customer_name,
                            "Subscription: "+subscription_code,
                            "Learners: "+ num_of_learners,
                            "Cohorts: "+ num_cohorts,
                            "Subscription End Date: "+ subscription_end_date,
                            "Paid Amount: "+ paid_amount,
                            "Total Amount: "+ total_amount,

                        ]
                },
                title: function() {
                    return 'Detailed Data';
                }
            },
          },
        },
    };
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            getCustomerReportData(userDetails.token)
        }
    },[]);

    const getCustomerReportData = async () => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            const response = await getCustomerReport(userDetails.token);
            if(!response.error){
                setCustomerData(response.data);
                let data = {
                    labels: [],
                    datasets: [
                      {
                        label: 'Number Of Learner',
                        data: [],
                        backgroundColor: [],
                        borderColor: "blue",
                        borderWidth: 1,
                      },
                      {
                        label: 'Number Of Cohorts',
                        data: [],
                        backgroundColor: [],
                        borderColor: "blue",
                        borderWidth: 1,
                      }
                    ],
                };
                for(let i=0; i<response.data.length; i++){
                    let data1 = response.data[i];
                    if(data1.num_of_learners === undefined || data1.num_of_learners === null){
                        data.datasets[0].data.push(0);
                    }else{
                        data.datasets[0].data.push(data1.num_of_learners);
                    }
                    if(data1.num_cohorts === undefined || data1.num_cohorts === null){
                        data.datasets[1].data.push(0);
                    }else{
                        data.datasets[1].data.push(data1.num_cohorts);
                    }
                    data.labels.push(data1.name);
                    data.datasets[0].backgroundColor.push('#F19CBB');
                    data.datasets[1].backgroundColor.push('#3B7A57');

                }
                setCustomerReport(data);
            }
        }
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='lg:py-10 py-6 lg:px-12 px-6'>
                    <div className='back-btn flex justify-start mb-6 md:mb-6'>
                        <a onClick={() => navigate('/superadmin/superadminreport')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div>
                    <h3 className='text-2xl text-secondary inter-700 lg:hidden'>Customer Report</h3>
                    <div className='back-btn flex justify-start mb-5 md:mb-8 hidden'>
                        {/* <a href='back' className='w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a> */}
                    </div>
                    <div>
                        {/* <h4 className='text-2xl inter-700 text-[#6E3174]'>Customer X Learner</h4> */}
                        <div className='mt-6'>
                            <div className='barg-graph-container'>
                                <Bar data={customerReport} options={options} />

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}